<template>
  <div class="px-5">
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{'mt-5': +i !== 0}"
      :ref="`print_request_${currentDetail.id}`"
      :id="`print_request_${currentDetail.id}`"
      class="navy-blue-border-1px border-15px">
      <div class="d-flex navy-blue-border-bottom-1px px-2 py-2">
        <span class="h6 text-sm mr-2">{{$t('general.created_at')}}:</span><span class="text-sm">{{currentDetail.created_at?moment(currentDetail.created_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
        
        <span class="mx-1 h25"> / </span>
        <span class="h6 text-sm mr-2">
          {{$t('general.updated_date')}}:</span><span class="text-sm"> {{currentDetail.updated_at?moment(currentDetail.updated_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
        
     </div>
        <div class="d-flex">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.processes') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.print_type') }}
          </div>
        </div>
        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Order', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_print_type_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.dispatch_location_ids"
                :name="`order_fabric_print_type_dispatch_location_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :model.sync="currentDetail.fabric_process_ids"
                :name="`order_fabric_print_type_fabric_print_type_${index}`"
                :options="fabricProcessOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center">
            <custom-multi-select
                :disabled="true"
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.press_type_id"
                :name="`order_fabric_print_type_fabric_process_${index}`"
                :not-list="true"
                :options="fabricPrintTypeOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
        </div>

        <date-detail-table-row
            :model="currentDetail"
        ></date-detail-table-row>

        <fabric-detail-table-row
            :model.sync="currentDetail"
            :order-size-and-color-options="orderSizeAndColorOptions"
            :amount-unit-options="amountUnitOptions"
            @colorChanged="orderSizeAndColorSave($event, index)"
        ></fabric-detail-table-row>

        <test-method-row
            :product-processes.sync="currentDetail.product_processes"
            :selected-test-methods.sync="currentDetail.selectedProductProcesses"
            :test-method-options="productProcesses"
        ></test-method-row>

        <currency-table-row
            :currency-options="currencyOptions"
            :currency-options-raw="currencyOptionsRaw"
            :currency-rate-options="currencyRateOptions"
            :expiration-type-options="expirationTypeOptions"
            :model="currentDetail"
            :v-a-t-rate-options="VATRateOptions"
        ></currency-table-row>

        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_yarn_fabric_description_${index}`"
                :required="false"
                :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })"
      ></span>

      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :order-id="orderId"
          name="print-request"
          :send-mail-url="sendMailUrl(index)"
          @addNewDetail="addNewDetail"
          @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
          @submit="submitOrderFabricPrint(index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import FabricDetailTableRow from "@/view/pages/order/layout/FabricDetailTableRow";
import TestMethodRow from "@/view/pages/order/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/order/layout/CurrencyTableRow";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import _ from "lodash";
import DateDetailTableRow from "@/view/pages/order/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/order/layout/BottomButtons";
import moment from "moment";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import {CREATE_ITEM, DELETE_ITEM_BY_ID} from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricPrintTypeTable",
  components: {
    BottomButtons,
    DateDetailTableRow,
    TextareaInput, CurrencyTableRow, TestMethodRow, FabricDetailTableRow, DatePickerInput, CustomMultiSelect
  },
  props: {
    model: {
      required: true,
    }, 
    totalInstructions:{
      required:false,
    },
    maxPrice:{
      required: false,
    },
    maxPriceUnit:{
      required:false,
    },
    previousModel: {
      required: true
    },
    amountUnitOptions: {
      required: true
    },
    defaultModel: {
      required: true,
    },
    yarnModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null
    },
    order: {
      required: true,
      default: null
    },
    currentInstructionTypeId: {
      required: true,
    },
    fabricPrintTypeOptions: {
      required: true,
    },
    fabricAdditionOptions: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true
    },
    expirationTypeOptions: {
      required: true,
    },
    VATRateOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    productProcesses: {
      required: true,
    },
    orderFabricOptions: {
      required: true,
    },
    tolerances: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    selectedPrintTypeId: {
      required: false,
    },
    orderSizeAndColorOptions: {
      required: true,
    }
  },

  data() {
    return {
      is_submitting: false
    }
  },
  methods: {
    maxPriceCheck(index){
      let modelTotal = 0;
      let fabricCurrencyUnit=this.maxPriceUnit;
      let allCurrencyRate=this.currencyRateOptions;
      let orderFabric = _.find(this.order.order_fabrics,{'id':+this.orderFabricId});
      let modelItem = this.model[index];

      let modelExchanegeRate=1;
      let modelCurrencyUnit=null;
      if(modelItem.currency_unit){
        modelCurrencyUnit=modelItem.currency_unit.code;
      }else{
        modelCurrencyUnit=this.currencyOptions[modelItem.currency_unit_id];
      }
      
      let findCurrencyForFabric=_.find(allCurrencyRate,{"code":fabricCurrencyUnit});
      let findCurrencyForModel=_.find(allCurrencyRate,{"code":modelCurrencyUnit});

      if((modelCurrencyUnit=="TL"||modelCurrencyUnit=="TRY")&&(fabricCurrencyUnit=="TRY"||fabricCurrencyUnit=="TL")){
        modelExchanegeRate=findCurrencyForFabric.rate;
        modelTotal+=Number(modelItem.total_price)/modelExchanegeRate;
      }else if(fabricCurrencyUnit==modelCurrencyUnit){
        modelTotal+=Number(modelItem.total_price);
      }else{

        modelExchanegeRate=findCurrencyForModel.rate/orderFabric.exchange_rate;
        modelTotal+=Number(modelItem.total_price)*modelExchanegeRate;
      }
      let modelPlusTotalAllInstructions=this.totalInstructions;
      if(modelPlusTotalAllInstructions>this.maxPrice){
        return false;
      }
      return true;
    },
    orderSizeAndColorSave(selectedValue, index) {
      let model = this.modelProxy[index];
      let value = Number(selectedValue);
      let amount = 0;
      let previousModels = this.previousModel.filter(previousModel => previousModel.order_size_and_color_id === +value);

      previousModels.forEach(previousModel => {
        amount += Number(previousModel.amount);
      })

      model.amount = amount ? amount.toFixed(2) : 0;
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/order/${this.orderId}/print-request/${itemId}`
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            self.modelProxy = [self.defaultModel];
          }
        } else {
          self.sweetAlertError(response.data.response.data.message);
        }
      });
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.amount = 0;
      copy.id = null;

      let orderFabricId = this.orderFabricId;
      let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(orderFabricId));

      copy.width = fabricItem.width;
      copy.weight = fabricItem.weight;

      this.modelProxy.push(copy);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];
      item.dispatch_locations = item.dispatch_location_ids.map(location => {
        return {
          id: location,
        }
      });
      item.fabric_processes = item.fabric_process_ids.map(process => {
        return {
          id: process,
        };
      });

      item.order_fabric_id = self.orderFabricId;
      item.raw_flow_date = moment(item.raw_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.raw_end_date = moment(item.raw_end_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_flow_date = moment(item.painted_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_end_date = moment(item.painted_end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitOrderFabricPrint(index) {
      let maxControl = this.maxPriceCheck(index);
      if(maxControl==false){
        this.sweetAlertError(this.$t('order.instruction_max_error'));
        return false;
      }
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/order/${self.orderId}/print-request`,
        contents: {
          order_print_requests: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          detail.order_fabric_excel_link = responseData.order_fabric_excel_link
          detail.remaining_amount = responseData.remaining_amount

          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      }).catch(error => {
        self.is_submitting = false;
        self.sweetAlertError(error);
        self.sweetAlertClose();
      });
    }
  },
  computed: {
    moment: () => moment,
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    totalAmount() {
      return function (index) {
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * (1 + (+this.modelProxy[index].tolerance_amount / 100))
        return (+this.modelProxy[index].total_amount).toFixed(0);
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;
        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.press_type_id) return false;
          if (!+current.supplier_company_id) return false;
          if (!current.fabric_process_ids.length) return false;
          if (!current.dispatch_location_ids.length) return false;
          if (current.fabric_type === '' || current.fabric_type === null) return false;
          if (current.color === '' || current.color === null) return false;
          if (current.color_ok === '' || current.color_ok === null) return false;
          if (current.weight === '' || current.weight === null) return false;
          if (!+current.width) return false;
          if (current.selectedProductProcesses.length === 0) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.order_size_and_color_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.unit_price) return false;
          if (!+current.vat_rate_id) return false;
          if (current.description === null || current.description === '') return false;
          return true;
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/order/${this.orderId}/print-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    if (this.previousModel && this.previousModel.length > 0) {
      this.modelProxy.forEach(model => {
        model.tolerance_amount = this.maxTolerance;
        model.order_fabric_id = this.orderFabricId;
        model.press_type_id = this.selectedPrintTypeId;

        let newId = model.order_fabric_id;
        let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(newId));

        if (!model.weight) {
          model.weight = fabricItem.weight;
        }

        if (!model.width) {
          model.width = fabricItem.width;
        }

        if (!+model.supplier_company_id) {
          model.supplier_company_id = this.previousModel[0].supplier_company_id;
        }
      })

      if(this.$route.query.qr_type=="order-print"){
        setTimeout(() => {
            let instructionsId = this.$route.query.data.id
            let element = this.$refs["print_request_"+instructionsId][0]
            if(element){
              element.scrollIntoView({behavior: "smooth"})
            }
          }, 2000);
      }
    }
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          let totalAmount = _.sumBy(this.modelProxy, 'amount');
          newValue.forEach(model => {
            model.fabric_supply_type_id = this.fabricProcessingType;
            model.tolerance_amount = this.tolerances ? this.tolerances[model.press_type_id] : 1;
            model.order_fabric_id = this.orderFabricId;
            model.press_type_id = this.selectedPrintTypeId;

            let newId = this.orderFabricId;
            let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(newId));

            if (!model.width) {
              model.width = fabricItem.width;
            }

            if (!model.weight) {
              model.weight = fabricItem.weight;
            }

            if (!model.amount) {
              model.amount = _.sumBy(this.previousModel, 'amount') - totalAmount;
            }
          })
        }
      },
      deep: true
    },
    orderFabricId(value) {
      this.modelProxy.forEach(model => {
        model.order_fabric_id = value;

        let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(value));

        if (!model.width) {
          model.width = fabricItem.width;
        }

        if (!model.weight) {
          model.weight = fabricItem.weight;
        }

        if (!model.amount) {
          model.amount = _.sumBy(this.previousModel, 'amount');
        }
      })
    },
    selectedPrintTypeId(value) {
      this.modelProxy.forEach(model => {
        model.press_type_id = value;
      })
    },
    tolerances(value) {
      if (value) {
        this.modelProxy.forEach(model => {
          model.tolerance_amount = this.tolerances ? this.tolerances[model.press_type_id] : 1;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
