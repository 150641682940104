<template> 
    <div class="row mx-0 " style="min-width:100%"> 
      <div class="card shadow-lg rounded w-100 my-4"> 
        <div class="card-header d-flex justify-content-between align-items-center">
          <h4>{{ $t("2. Kalite Kapama Raporu") }}</h4>
          <i class="fas fa-times ml-auto float-right" @click="closeSecondReport"></i>
        </div>
        <div class="card-body"> 
          <div class="col-12 mx-0 py-2" >
            <div class="top_header">Tedarikçi Kapama Raporu</div>
            <div style="border:1px solid black;padding:5px"> 
              <table class="table backgrid navy-blue-border-1px pt-3 px-0 border-15px">
                  <thead class="">
                  <tr> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Tedarikçi
                    </th>
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Oluşturan
                    </th>
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Sipariş Adeti
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Renk  
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Kesim Adeti 
                    </th>  
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Yüklenen Adet
                    </th> 
                      <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Numune
                    </th> 
                      <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      İkinci Kalite
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Parça İade
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Toplam
                    </th> 
                  </tr>
                  </thead>
                  <tbody> 
                    <tr  v-for="item in items" :key="item.id"> 
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{item.order_expense_detail.supplier_company ? item.order_expense_detail.supplier_company.name : '-'}}</td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{item.supplier_user ? item.supplier_user.name : '-'}}</td> 
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{ orderAmount(item) }}</td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" > 
                        {{ item.order_expense_detail ? item.order_expense_detail.order_size_and_color.color_name : 'COLOR'}}
                      </td>  
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                        {{filterCutAndSum(item)}}
                      </td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                        {{item.loaded_quantity_supplier  == null  ? exportAmount(item) : item.loaded_quantity_supplier }}
                      </td>
                        <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                        {{item.sample_amount_supplier  ?? '-'}}
                      </td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                        {{item.second_quantity_supplier  ?? '-'}}
                      </td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                        {{item.part_return_supplier  ?? '-'}}
                      </td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{item.total_supplier ?? '-'}}</td>
                    </tr>
                  </tbody> 
              </table>
              <div class="d-flex justify-content-end items-center">
                  <img v-show="document"
                      id="following_document" :src="document"
                      alt=""
                      class="w-50px h-50px object-cover border-15px navy-blue-border-1px mr-5"> 
              </div>
            </div>
          </div> 
          <div class="col-12 mx-0 py-2"  >
            <div class="top_header">Pulpopro Kapama Raporu</div>
             <div style="border:1px solid black;padding:5px">               
              <table class="table backgrid navy-blue-border-1px pt-3 px-0 border-15px">
                  <thead class="">
                  <tr> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Tedarikçi
                    </th>
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Oluşturan
                    </th>
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Sipariş Adeti
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        Renk  
                    </th>  
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">Kesim adeti 
                    </th>
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Yüklenen Adet
                    </th> 
                      <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Numune
                    </th> 
                      <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      İkinci Kalite
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Parça İade
                    </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Toplam
                    </th> 
                  </tr>
                  </thead>
                  <tbody> 
                    <tr v-for="item in items" :key="item.id"  > 
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{item.order_expense_detail.supplier_company ? item.order_expense_detail.supplier_company.name : '-'}}</td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{item.user.name }}</td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{ orderAmount(item)}}</td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" > {{ item.order_expense_detail ? item.order_expense_detail.order_size_and_color.color_name : 'Color'}}</td>  
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{filterCutAndSum(item)}}</td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" :style="item.loaded_quantity_manager < item.loaded_quantity_supplier ? 'background:#f8adad' : '' " > 
                        {{item.loaded_quantity_manager  == null  ? exportAmount(item) : item.loaded_quantity_manager }}
                      </td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" :style="item.sample_amount_manager < item.sample_amount_supplier ? 'background:#f8adad' : '' ">
                        {{item.sample_amount_manager ?? '-'}} 
                      </td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" :style="item.second_quantity_manager < item.second_quantity_supplier? 'background:#f8adad' : '' ">
                          {{item.second_quantity_manager  ?? '-'}}  
                      </td>
                      <td
                        class="navy-blue-border-bottom-1px navy-blue-border-right-1px" :style="item.part_return_manager < item.part_return_supplier ? 'background:#f8adad' : '' ">
                        {{item.part_return_manager  ?? '-'}}  
                      </td>
                      <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{item.total_manager ?? '-'}}</td>
                    </tr>
                  </tbody> 
              </table>
             </div>
          </div> 
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "SecondQualityTable",
  props: ["items"],
  data() {
    return {
      reportType: null,
    };
  },
  computed: { 
    
  },
  methods: {
    filterCutAndSum(item){
      // JSON.parse(item.order.calculate_data.color_and_total_cut_amounts) //bunu sonra kullan.
      const filteredItems = item.order.cut_expense_sum_actual.filter(actualItem => {
        return actualItem.color_id === item.order_expense_detail.order_size_and_color.id
        && actualItem.supplier_id === item.order_expense_detail.supplier_company_id;
      }); 
      let totalCut=0;
      filteredItems.forEach(value => {
        if(value !=0){
          totalCut +=value.total
        }
      })

     return totalCut;// filteredItems[0].total
    },
    selectReport(value) {
      this.reportType = this.reportType!=null ? null : this.reportType;
      this.$emit("selectReport", value);
    },   
    orderAmount(data) { 
        let size_and_colors =  data.order.order_size_and_colors; 
        let parentTotal = 0;  
        size_and_colors.forEach((item)=> {   
          if(data.order_expense_detail.order_size_and_color.id ==item.id ){
            let item_body_sizes = JSON.parse(item.body_sizes)  
            for (var key in item_body_sizes) {
                if (item_body_sizes.hasOwnProperty(key)) {
                    var value = item_body_sizes[key];    
                    parentTotal += Number(value.amount)
                }
            }  
          }
        }) 
        return parentTotal;
    },  
    exportAmount(data){
      let amount = 0; 
      data.order.packing.forEach((pack)=> {
        if( pack.supplier_company_id == data.order_expense_detail.supplier_company_id && pack.order_size_and_color_id == data.order_expense_detail.order_size_and_color_id) 
          { 
            amount += pack.amount 
          }
      });  
        return amount;
    },   
    closeSecondReport() {
      this.$emit("closeSecondReport", true);
    },

  }, 
};
</script>
<style>
.top_header{
  font-size: 18px;
  font-weight: 7000;
  color: #f2f2f2; 
  background: #000000;
  padding: 5px;
  
}
.top_data{
  display:flex;
  padding:20px 0px
}
.top_data span{
  margin-left:20px
}
</style>
