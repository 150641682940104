<template>
  <div class="col-12">
    <h5 class="m-2">{{ $t("order_form.forms") }}</h5>
    <div class="row mx-3 d-flex justify-content-left">
      <div
        class="p-2 excel align-items-center box color-1"
        role="button"
        @click="changeForm(1)"
      >
        <i class="fa fa-file fa-3x m-2 icon"> </i>
        <span class="label label-rounded label-danger count-label" v-if="formCounts">{{
          formCounts.mid_control.count
        }}</span>
        <h6 class="order-inner-title">{{ $t("order_form.mid_control") }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <template v-if="openForm">
          <mid-control
            :body-sizes="bodySizes"
            :order-size-and-colors="orderSizeAndColorOptions"
            :default-model="defaultMidControlForm"
            :model.sync="midControlForm"
            :order="order"
            :supplierCompanies="supplierCompanies"
            :form-stop-options="formStopOptions"
            v-if="selectFormId == 1"
          ></mid-control>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  CREATE_ITEM,
} from "@/core/services/store/REST.module";
import MidControl from "@/view/pages/order/forms/MidControl.vue";
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      selectFormId: null,
      openForm: 0,
      midControlForm: [{}],
      supplierCompanies: {},
      formCounts: [],
      defaultMidControlForm: {
        id: null,
        user_id: null,
        order_id: null,
        qr_code: null,
        form_type: null,
        sizes: [],
        is_editing: false,
        stop_options: [],
        supplier_company_id: null,
        operation_type: null,
        status: 1,
        measuring_location: [],
        measures: null,
        errors: null,
        date: moment(),
        user:[]
      },
    };
  },
  components: {
    MidControl,
  },
  props: {
    formStopOptions: {
      required: true,
    },
    order: {
      required: true,
    },
    csr: {
      required: true,
    },
    bodySizes: {
      required: true,
    },
  },
  mounted() {
    this.midControlForm = [_.cloneDeepWith(this.defaultMidControlForm)];
    this.getSupplierCompanies();
    this.getFormsCount();
  },
  computed: {
    formatBodySizes() {
      let items = this.bodySizes;
      return this.convertArrayToObjectByKey(items, "id", "name");
    },
    orderSizeAndColorOptions() {
      let tmpItems = {};

      this.order.order_size_and_colors.forEach((color) => {
        let body_sizes = _.cloneDeep(color.body_sizes);

        Object.keys(body_sizes).map((key) => {
          tmpItems[key]=body_sizes[key];
        });

      });
      return tmpItems;
    },
  },
  methods: {
    changeForm(fromId) {
      this.openForm = !this.openForm;
      this.selectFormId = fromId;
      this.getRequests();
    },
    getRequests() {
      if (!this.selectFormId) return false;

      let self = this;
      let url = "api/order/" + this.order.id + "/form";
      this.sweetAlertLoading();
      this.$store
        .dispatch(GET_ITEMS, {
          url: url,
        })
        .then((result) => {
          if (result.status) {
            let data = result.data;
            let tmpItems = [];
            let tmpRequests = [];

            self.midControlForm = [{}];

            if (this.selectFormId == 1) {
              // Getting print requests
              tmpRequests = data.mid_control;
              tmpItems = [];
              tmpRequests.forEach((item, index) => {
                item.is_editing = true;
                tmpItems.push(item);
              });
              if (!tmpRequests.length) {
                tmpItems.push(_.cloneDeepWith(this.defaultMidControlForm));
              }

              self.midControlForm = tmpItems;
            }
          }
          this.sweetAlertClose();
        });
    },
    getSupplierCompanies() {
      this.sweetAlertClose();
      this.sweetAlertLoading();
      let content = {
        
      };

      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/order/supplier-companies-for-form",
          contents: {
            order_id: this.order.id,
          },
        })
        .then((results) => {
          if (results.status) {
            this.supplierCompanies = this.convertArrayToObjectByKey(
              results.data,
              "id",
              "name"
            );
          }
        });
      this.sweetAlertClose();
    },
    getFormsCount() {
      this.sweetAlertClose();
      this.sweetAlertLoading();
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/order/" + this.order.id + "/form/count",
        })
        .then((results) => {
          if (results.status) {
            this.formCounts = results.data;
          }
        });
      this.sweetAlertClose();
    },
  },
};
</script>

<style scoped lang="scss">
.color-1 {
  border: 1px solid #5a63a9;
  background-color: #525886;
}

.count-label {
  position: absolute;
  right: 5px;
  top: 5px;
}

.box {
  position: relative;
  border-radius: 8px;
  color: white;
  display: grid;
  width: 100%;
  text-align: center;
  width: 12.5% !important;
  margin: 0.3rem;
  .icon {
    color: white;
  }
  .remove-button {
    position: absolute;
    color: white;
    top: 0px;
    right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    width: 47.2% !important;
  }
}

@media screen and (max-width: 350px) {
  .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 23.99% !important;
  }
}
</style>
