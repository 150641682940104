<template>
  <div class="col-12">
    <h5 class="m-2">Belgeler</h5>
    <template>
      <div>
        <VueFileAgent
          ref="vueFileAgent"
          v-model="files"
          :helpText="$t('order_file.click_or_drap_and_drop')"
          :multiple="true"
          :deletable="true"
          :value="previewOptions"
          :accept="acceptedFormats"
          :linkable="true"
          @rename="handleFileRename($event)"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
        >
        </VueFileAgent>
        <button
          v-if="fileRecordsForUpload.length"
          class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer ml-2 mt-1"
          @click="uploadFiles()"
        >
          Kaydet {{ fileRecordsForUpload.length }} Dosya
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import "vue-file-agent/dist/vue-file-agent.css";
import _ from "lodash";
export default {
  data() {
    return {
      files: [],
      previewOptions: [],
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
      acceptedFormats:
        "image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  },
  props: {
    orderId: {
      required: true,
    },
  },
  mounted() {
    this.getOrderFiles();
  },
  methods: {
    handleFileRename(file) {
      console.log(file);
    },
    getOrderFiles() {
      let self = this;
      self.$store
        .dispatch(CREATE_ITEM, {
          url: `api/order/${self.orderId}/files`,
        })
        .then((result) => {
          if (result.status) {
            let files = result.data;
            files.forEach((file) => {
              this.files.push({
                id: file.id,
                name: file.name,
                size: file.size,
                type: file.mime_type,
                ext: file.type,
                url: file.file_link,
              });
            });
          }
        });
    },
    uploadFiles() {
      if (!this.isUserGranted("OrderFile", ["create"], true, true)) {
        return false;
      }
      this.sweetAlertLoading();
      let self = this;
      let contents = this.fileRecordsForUpload;
      let files = new FormData();
      contents.forEach((content) => {
        files.append("files[]", content.file);
      });
      self.$store
        .dispatch(CREATE_ITEM, {
          url: `api/order/${self.orderId}/file`,
          acceptPromise: true,
          contents: files,
        })
        .then((result) => {
          if (result.status) {
          }
        });
      this.fileRecordsForUpload = [];
      this.sweetAlertClose();
    },
    deleteUploadedFile(fileRecord) {
      this.sweetAlertLoading();
      var i = this.files.indexOf(fileRecord);
      if (i !== -1) {
      } else {
        return false;
      }
      let self = this;
      self.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: `api/order/file/${fileRecord.id}/delete`,
        })
        .then((result) => {
          if (result.status) {
            this.files.splice(i, 1);
          }
        })
        .catch((e) => {
          this.sweetAlertError(e.data.message);
        });
      this.sweetAlertClose();
    },
    onBeforeDelete(fileRecord) {
      if (!this.isUserGranted("OrderFile", ["delete"], true, true)) {
        return false;
      }
      var i = this.fileRecordsForUpload.indexOf(fileRecord);

      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.files.indexOf(fileRecord);
        if (k !== -1) this.files.splice(k, 1);
      } else {
        let message = this.$t("order_file.are_you_sure_delete_file");
        this.sweetAlertConfirm(message, null, null).then((reply) => {
          if (reply) {
            this.deleteUploadedFile(fileRecord); // will trigger 'delete' event
          }
        });
      }
    },
    filesSelected(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    fileDeleted(fileRecord) {
      var i = this.files.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
  computed: {
    isValidSubmitButton() {
      if (!fileRecordsForUpload.length) {
        return false;
      }
      if (!this.isUserGranted("OrderFile", ["create", "update"], false, true)) {
        return false;
      }
      return true;
    },
    deletable() {
      if (!this.isUserGranted("OrderFile", ["delete"], false, true)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.jpg {
  border: 1px solid #e39802;
  background-color: #fcae10;
}
.pdf {
  border: 1px solid #fe3f38;
  background-color: #fa3b3b;
}
.word {
  border: 1px solid #116cff;
  background-color: #4384f5;
}
.excel {
  border: 1px solid #0a7253;
  background-color: #0fa376;
}
.box {
  position: relative;
  border-radius: 8px;
  color: white;
  display: grid;
  width: 100%;
  text-align: center;
  width: 12.5% !important;
  margin: 0.3rem;
  .icon {
    color: white;
  }
}

.box:hover {
  overflow: hidden; /* İçeriğin taşmasını önlemek için */
}

.remove-button {
  position: absolute;
  color: white;
  top: 0px;
  right: 0px;
  transition: transform 0.3s; /* Dönme animasyonu için geçiş süresi */
}

.remove-button:hover {
  transform: rotate(360deg); /* Dönme animasyonu */
}

.box:hover::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border: 2px solid rgb(6, 0, 0);
  border-radius: 50%;
  animation: particles 1s infinite;
  z-index: -1;
}

@keyframes particles {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .box {
    width: 47.2% !important;
  }
}

@media screen and (max-width: 350px) {
  .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 23.99% !important;
  }
}
</style>
