<template>
  <div class="conteiner-fluid">
    <div class="row mx-0 py-7 text-sm">
      <div class="col-12">
        <h3 class="text-center mb-2">
          {{ data["app_name"] }} {{ data["order_number"] }}
          {{ lang["order.order_form_small"] }}
        </h3>
      </div>
      <div class="col-12">
        <table class="table table-sm">
          <tbody>
            <tr>
              <td>
                {{ lang["order.order_no"] }}:
                <b class="ml-2">{{ data["order_number"] }}</b>
              </td>
              <td>
                {{ lang["order.date"] }}: <b class="ml-2">{{ data["order_date"] }}</b>
              </td>
              <td colspan="2">
                {{ lang["order.export_dates"] }} :
                <b class="ml-2"> {{ data["order_export_dates"] }} </b>
              </td>
              <td rowspan="5">
                <div style="height: 90px; width: auto; float: right">
                  <img
                    class="d-inline-block mb-0 m-0 p-0"
                    style="width: 80px; margin-left: 10px; height: 90px"
                    :src="data['image_link']"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td :colspan="data['manufacturers'].length > 75 ? 4 : ''">
                {{ lang["order.customer"] }} :
                <b class="ml-2">{{ data["company"]["name"] }} </b>
              </td>

              <td :colspan="data['manufacturers'].length < 75 ? 3 : 4">
                {{ lang["order.manufacturer"] }}:
                <b class="ml-2"> {{ data["manufacturers"] }} </b>
              </td>
            </tr>
            <tr>
              <td>
                {{ lang["order.customer_representative"] }}:
                <b class="ml-1"> {{ data["user"]["name"] }} </b>
              </td>
              <td>
                {{ lang["order.po_no"] }} : <b class="ml-2">{{ data["po_number"] }} </b>
              </td>
              <td>
                {{ lang["order.amount"] }} : <b class="ml-2"> {{ data["amount"] }} </b>
              </td>
              <td>
                {{ lang["order.model_no"] }} : <b class="ml-2"> {{ data["model"] }} </b>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="t-center">
                {{ lang["order.description"] }}
                <b>{{ data["description"] }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <table class="table table-sm">
          <thead>
            <tr class="table-active">
              <th style="width: 20%">{{ lang["order.fabric_type"] }}</th>
              <th style="width: 5%">{{ lang["order.width"] }}</th>
              <th style="width: 5%">{{ lang["order.weight"] }}</th>
              <th style="width: 5%">{{ lang["order.pastal"] }}</th>
              <th style="width: 5%">{{ lang["order.unit"] }}</th>
              <th style="width: 20%">{{ "Kumaş Termin" }}</th>
              <th style="width: 5%">{{ lang["general.total"] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order_fabric in data['order_fabrics']">
              <td>{{ order_fabric["type"] }}</td>
              <td>{{ order_fabric["width"] }}</td>
              <td>{{ order_fabric["weight"] }}</td>
              <td>{{ order_fabric["pastal"] }}</td>
              <td>{{ order_fabric["unit_price"] }}</td>
              <td>
                {{ moment(order_fabric["deadline_start"]).format("DD-MM-YYYY") }},
                {{ moment(order_fabric["deadline_end"]).format("DD-MM-YYYY") }}
              </td>
              <td>{{ order_fabric["planned"] }}</td>
            </tr>
            <tr>
              <th colspan="6">{{ lang["general.total"] }}</th>
              <th class="text-center">{{ orderFabricTotal.toFixed(2) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <table class="table table-sm">
          <thead>
            <tr class="table-active">
              <th style="width: 96%">{{ lang["order.expense_short"] }}</th>
              <th style="width: 4%">{{ lang["order.total"] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order_expense in data['order_expenses']">
              <td>{{ order_expense["expense"]["translations"][0]["name"] }}</td>
              <td class="t-left">{{ order_expense["planned"] }}</td>
            </tr>
            <tr>
              <th>{{ lang["general.total"] }}</th>
              <th>{{ expenseTotal.toFixed(3) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <table class="table table-sm">
          <tbody>
            <tr>
              <th>{{ lang["order.total_cost"] }}</th>
              <th style="width: 4%">
                {{ numberFormat((expenseTotal + orderFabricTotal).toFixed(3)) }}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <table class="table table-sm">
          <thead>
            <tr class="table-active">
              <th>{{ lang["order.color_number"] }}</th>
              <th>{{ lang["order.color_name"] }}</th>
              <template v-for="(size, key) in sizes">
                <template v-if="data_size_names_head.includes(size)">
                  <th>{{ size }}</th>
                </template>
              </template>
              <th>{{ lang["order.total"] }}</th>
              <template v-for="order_fabric in data['order_fabrics']">
                <th>{{ order_fabric["type"] }}</th>
              </template>
              <th>{{ lang["order.total"] }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="order_size_and_color in data['order_size_and_colors']">
              <sizes-amount
                :order-size-and-colors="order_size_and_color"
                :sizes="sizes"
                :order-fabrics="data['order_fabrics']"
                :data-size-names-head="data_size_names_head"
              >
              </sizes-amount>
            </template>
            <sub-total
              :data="data"
              :sizes="sizes"
              :order-fabrics="data['order_fabrics']"
              :data-size-names-head="data_size_names_head"
              :lang="lang"
            >
            </sub-total>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SizesAmount from "@/view/pages/order/pdf/order-pdf-layout/SizesAmount";
import SubTotal from "@/view/pages/order/pdf/order-pdf-layout/SubTotal";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
export default {
  name: "orderReport",
  props: {
    data: {
      required: true,
    },
    lang: {
      required: true,
    },
  },
  components: {
    SizesAmount,
    SubTotal,
  },
  computed: {
    moment: () => moment,
    orderFabricTotal() {
      let orderFabricTotal = 0;
      this.data["order_fabrics"].forEach((order_fabric) => {
        orderFabricTotal += +order_fabric["planned"];
      });
      return orderFabricTotal;
    },
    expenseTotal() {
      let expenseTotal = 0;
      this.data["order_expenses"].forEach((order_expense) => {
        expenseTotal += +order_expense["planned"] * order_expense["amount"];
      });
      return expenseTotal;
    },
    sizes() {
      return this.data["body_sizes"];
    },
    data_size_names_head() {
      let items = [];
      this.data["order_size_and_colors"].forEach((order_size_and_color) => {
        let parsedItems = JSON.parse(order_size_and_color.body_sizes);
        Object.entries(parsedItems).forEach(([key, body_name]) => {
          let size = body_name["name"];
          items.push(size);
        });
      });
      return items;
    },
    data_size_names() {
      let data = [];
      this.sizes.forEach((size) => {
        if (this.data_size_names_head.includes(size)) {
          data.push(size);
        }
      });
      return data;
    },
    data_size_amount() {
      let data = {};
      this.sizes.forEach((size) => {
        if (this.data_size_names_head.includes(size)) {
          data[size] = 0;
        }
      });
      this.data["order_size_and_colors"].forEach((color) => {
        let body_sizes = JSON.parse(color.body_sizes);
        Object.entries(body_sizes).forEach((body_name) => {
          data[body_name[1]["name"]] = body_name[1]["amount"];
        });
      });
      return data;
    },
  },
  methods: {
    downloadPdf() {
      this.$emit("downloadPdf");
    },
    closeModal() {
      this.$emit("downloadPdf");
    },
    numberFormat(number) {
      return Number(number).toLocaleString("tr-TR", { minimumFractionDigits: 2 });
    },
  },
};
</script>
<style scoped lang="scss">
tr {
  height: 25px !important;
}
@page {
  size: auto !important;
}
</style>
