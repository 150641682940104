<template>
  <div class="row">
    <b-modal id="instructions-modal" ref="instructions-modal" hide-footer hide-header hide-header-close scrollable
             size="xl">
      <instructions-modal
          :expenses="expenses"
          :instruction-types="instructionTypes"
          :items="instructions"
          :units="options.unitOptions3"
          @onCloseModal="onCloseModal"
      />
    </b-modal>

    <b-modal id="display-order-pdf" ref="display-order-pdf" hide-header hide-header-close scrollable size="xl">
          <div id="reportPrint">
            <order-pdf-report
            :lang="orderReportData.lang"
            :data="orderReportData.data"
            >
          </order-pdf-report>
          </div>
          <template #modal-footer>
            <div class="col-12 text-center">
              

              <button
                class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
                @click="closeModal('display-order-pdf')"
              >
                {{ $t("general.close") }}
              </button>
        
              <button
                class="btn btn-sm btn-outline-info p-2 btn-pill font-weight-bold cursor-pointer mr-5"
                v-print="reportPrintObject"
              >
              {{ $t("general.print") }}
              </button>

              <button
                class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
                @click="downloadPdf"
              >
                {{ $t("order.download") }}
              </button>
            </div>
          </template>
    </b-modal>

    <modal v-if="form.id" :adaptive="true" height="auto" name="open-qr-code-modal" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll d-flex justify-content-center mt-5">
          <q-r-code
              :code="form.code"
              :font-classes="'text-lg font-weight-boldest'" :size="300"
              :value="form.pdf_link"
              level="H"
          ></q-r-code>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                  type="button"
                  @click="closeModalByName('open-qr-code-modal')">{{ $t('editSample.close') }}
          </button>
        </div>
      </div>
    </modal>

    <modal :adaptive="true" height="auto" name="open-export-date-modal" width="1000">
      <div class="card-body card-body-scroll mt-5"
      style="min-height: 260px;"
      >
        <template v-if="tmpOrderSizeAndColorId!=null ">
          <div class="col-12">
            <div class="mb-3 font-weight-bold">{{ $t('general.date') }}</div> 
            <custom-multi-select
                :model.sync="form.order_size_and_colors[tmpOrderSizeAndColorId].selected_dates"
                :not-list="false"
                :options="formatedOrderExport"
                :required="false"
                :show-invalid="true"
                :title="null"
                object-key="name"
                :name="null"
             ></custom-multi-select>
          </div>
          <div 
          v-if="form.order_size_and_colors[tmpOrderSizeAndColorId].export_dates && form.order_size_and_colors[tmpOrderSizeAndColorId].export_dates.length"
          class="w-100 row navy-blue-border-1px mx-3 border-15px" 
          style="overflow-x: auto;">
            <div  class="col-12 row mx-0 navy-blue-border-bottom-1px"
            v-for="export_date,indexDate in form.order_size_and_colors[tmpOrderSizeAndColorId].export_dates">
              <div class="w-300px row mx-0 py-3 navy-blue-border-right-1px">
                <div class="col-12 navy-blue-border-bottom-1px font-weight-bold">{{ $t('general.date') }}:{{ export_date.date | laravelDateToSystem }}</div>
                <div class="col-6 text-lg font-weight-bold ">
                  <div>{{ $t('order.color_name') }}</div>
                  <input
                  disabled
                  v-model="export_date.color_name" :placeholder="$t('order.color_name')"
                  class="form-control form-control-sm"
                  step="1" type="text">
                </div>
                <div class="col-6 text-lg font-weight-bold">
                  <div>{{ $t('order.color_number') }}</div>
                  <input
                  disabled
                  v-model="export_date.color_number" :placeholder="$t('order.color_number')"
                  class="form-control form-control-sm"
                  step="1" type="number">
                </div>
              </div>
              <div class="flex-grow-1 mx-0" style="min-width: 150px !important; max-width: calc(100% - 300px);">
                <div class="table-responsive h-100">
                  <table class="table h-100">
                    <thead>
                    <tr>
                      <th v-for="(value, key) in export_date.body_sizes"
                          class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        <div class="w-100 d-flex justify-content-center items-center">
                          <span class="d-inline-block px-5">{{ value.name }}</span>
                        </div>
                      </th>
                      <th class="text-center navy-blue-border-bottom-1px">

                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td v-for="(value, key) in export_date.body_sizes" class="navy-blue-border-right-1px">
                       {{$t('order.max:')}}:{{ Number(Number(value.new_amount) * 1.05).toFixed(4) }}
                        <div class="d-flex justify-content-center">
                          <input v-model="value.amount"
                                 :class="{'is-invalid':  Number(value.amount) > Number(value.new_amount) * 1.05 }"
                                 class="form-control form-control-sm" type="number">
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-center">
                          <span @click="deleteExportDateBodySize(indexDate)" class="button d-flex">
                            <i class="far fa-trash-alt mr-1" style="color:#d64a4a"></i>
                            {{ $t('general.delete') }}
                          </span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                type="button"
                @click="closeModalByName('open-export-date-modal')">{{ $t('editSample.close') }}
        </button>
      </div>
    </modal>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="pt-5 pb-10 d-flex items-start">
              <div class="w-75px">
                <div class="navy-blue-border-1px w-auto w-75px d-xl-none d-lg-none d-md-block d-sm-block mb-4">
                  <div v-if="form.id" class="cursor-pointer" @click="$modal.show('open-qr-code-modal')">
                    <q-r-code
                        :code="form.code" :size="75"
                        :value="form.pdf_link"
                        level="H"
                    ></q-r-code>
                  </div>
                </div>
                <div class="navy-blue-border-1px w-auto w-75px h-75px" style="overflow: hidden; border-radius: 10px;">
                  <img id="image_holder" :src="form.image_link ? form.image_link : getIconByKey('general.no-image')"
                       class="w-75px h-75px d-inline-block object-cover cursor-pointer"
                       style="" @click="getOriginalImage">
                </div>
                <input ref="orderImage" class="input-file form-control" style="display: none" type="file"
                       @change="onChangeOrderImage">
                <div class="w-20px h-20px ml-8" @click="$refs.orderImage.click()">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-30px h-30px d-inline-block object-fill cursor-pointer'
                })">
                </span>
                </div>
              </div>
              <div class="flex-grow-1 row items-end mx-3">
                <div v-if="type === 2" class="col-xl-2 col-lg-2 col-md-3 col-sm-6 text-lg">
                  <div class="d-block mr-3 font-weight-boldest">{{ $t('order.collection_number') }} :</div>
                  <div class="d-block">
                    <button-with-icon
                        :icon-name="null"
                        :text="form.sample ? form.sample.collection_number : $t('order.select')"
                        @onClick="redirectToSelectSample">
                    </button-with-icon>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 text-lg mb-3">
                  <div class="d-block mr-3 font-weight-boldest">{{ $t('order.esc_no') }} :</div>
                  <div class="d-block">
                    <button-with-icon
                        :icon-name="null"
                        :text="form.esc ? form.esc.esc_number : $t('order.select')"
                        @onClick="redirectToSelectESC">
                    </button-with-icon>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                  <custom-multi-select
                      :disabled="!form.esc_table_id"
                      :is-inline="false"
                      :item-per-row="1"
                      :max="1"
                      :model.sync="form.company_id"
                      :not-list="true"
                      :options="options.customerOptions"
                      :title="$t('order.customer') + ':'"
                      class="font-weight-bolder text-xl"
                      input-max-width="125px"
                      input-width="auto"
                      name="order_company_id"
                      @save="onCompanyChange"
                  ></custom-multi-select>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 text-lg mb-3 text-lg">
                  <custom-multi-select
                      :is-inline="false"
                      :item-per-row="1"
                      :max="1"
                      :model.sync="form.status_id"
                      :not-list="true"
                      :options="options.orderStatusOptions"
                      :placeholder="$t('order.please_select_status')"
                      :title="$t('order.order_status') + ':'"
                      class="font-weight-bolder"
                      name="order_status_id"
                  ></custom-multi-select>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                  <number-input
                      :helper-text="$t('order.please_enter_manually')"
                      :is-inline="false"
                      :model.sync="form.amount"
                      :required="true"
                      :title="$t('order.total')"
                      input-width="125px"
                      label-class="font-weight-boldest py-0 text-xl"
                      name="order_total"
                  ></number-input>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                  <text-input
                      :disabled="form.esc_table_id === null"
                      :helper-text="$t('please_enter_manually')"
                      :is-inline="false"
                      :model.sync="form.model"
                      :placeholder="''"
                      :title="$t('order.model_no') + ':'"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="order_model_no"
                  ></text-input>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                  <text-input
                      :disabled="form.esc_table_id === null"
                      :helper-text="$t('order.please_enter_manually')"
                      :is-inline="false"
                      :model.sync="form.po_number"
                      :title="$t('order.po_no') + ':'"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="order_po_no"
                  ></text-input>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                  <span class="font-weight-boldest text-xl d-block mr-2">{{ $t('order.order_no') }} : </span>
                  <span class="d-block ml-2"> {{ form.order_number ? form.order_number : ' - ' }}</span>
                </div>
              </div>
              <div v-if="(form && 'qr_code_link' in form && form.qr_code_link !== null)"
                   class="w-75px h-75px d-xl-block d-lg-block d-md-none d-sm-none">
                <div class="navy-blue-border-1px w-auto w-75px h-75px text-center">
                  <div v-if="form.id" class="cursor-pointer" @click="$modal.show('open-qr-code-modal')">
                    <q-r-code
                        :code="form.code" :size="75"
                        :value="form.pdf_link"
                        level="H"
                    ></q-r-code>
                  </div>
                  <button class="btn btn-primary btn-xs" @click="showInstructions">{{
                      $t('general.instructions')
                    }}
                  </button>
                </div>
              </div>
            </div>

            <div class="w-100 d-flex justify-content-center">

              <div class="w-lg-75 w-md-100 w-sm-100 w-100 row text-center font-weight-boldest text-xl">
                <div :class="{'bg-primary text-white custom-tab-box': currentActiveTab === 4}"
                     class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-right-0 border-radius-left-bottom-10px border-radius-left-top-10px"
                     @click="currentActiveTab = 4">
                  {{ $t('csr.self').toUpperCase() }}
                </div>
                <div
                id="general"
                 :class="{'bg-primary text-white custom-tab-box': currentActiveTab === 1}"
                     class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-right-0"
                     @click="isValidChangeGeneralTab()?currentActiveTab = 1:''">
                  {{ $t('order.general').toUpperCase() }}
                </div>
                <div :class="{'bg-primary text-white custom-tab-box': currentActiveTab === 2}"
                     class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-right-0"
                     @click="form.is_submitted  ? currentActiveTab = 2 : ''">
                  {{ $t('order.suppliers_and_manufacturer').toUpperCase() }}
                </div>
                <div :class="{'bg-primary text-white custom-tab-box': currentActiveTab === 3}"
                     class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-radius-right-bottom-10px border-radius-right-top-10px"
                     @click="form.is_submitted && isValidFabricComposition() ? currentActiveTab = 3 : ''">
                  {{ $t('order.report').toUpperCase() }}
                </div>
              </div>
            </div>

            <div v-if="(currentActiveTab === 4 && isValidToContinueOnGeneral)" class="row py-7 px-3 items-start">
              <div class="p-2 d-flex">
                <div class="flex-grow-1 row p-3">
                  <div class="col-12">
                    <custom-multi-select
                        :helperText="$t('general.please_select')"
                        :is-inline="false"
                        :model.sync="csr.join_life"
                        :options="joinLifeOptions"
                        :required="true"
                        :show-invalid="false"
                        :title="$t('csr.join_life_spec')"
                        class="font-weight-bolder"
                        input-max-width="100%"
                        input-width="100%"
                        name="join_life"
                        width-class="100%"
                    />
                  </div>

                  <div class="col-12">
                    <custom-multi-select
                        :helperText="$t('general.please_select')"
                        :is-inline="false"
                        :model.sync="csr.disney_licences.disney_id"
                        :options="yesNoOptions"
                        :required="true"
                        :show-invalid="false"
                        :title="$t('csr.disney')"
                        class="font-weight-bolder"
                        input-max-width="100%"
                        input-width="100%"
                        name="disney"
                        width-class="100%"
                    />
                  </div>
                  <div class="col-12">
                    <custom-multi-select
                        :helperText="$t('general.please_select')"
                        :is-inline="false"
                        :model.sync="csr.disney_licences.license_id"
                        :options="disneyLicences"
                        :required="true"
                        :show-invalid="false"
                        :title="$t('csr.licence')"
                        class="font-weight-bolder"
                        input-max-width="100%"
                        input-width="100%"
                        name="licence"
                        width-class="100%"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 text-center">
                <div class="col flex-grow-1 p-3">
                  <div class="flex-grow-1 justify-content-center row">
                    <div class="d-flex justify-content-center mt-3">
            <span
                class="px-3 green-border-1px rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer"
                @click="$router.push({name: 'order.index'})">
              <span v-html="getIconByKey('icons.waybill.give_up', {
                class: 'w-35px h-35px object-cover d-inline-block opacity-75'})">
              </span>
              {{ $t('general.give_up').toUpperCase() }}
            </span>
                      <button-with-icon
                          :disabled="!(isUserGranted('Order', ['create', 'update'], false) || isUserGranted('Csr', ['create','update'], false ))"
                          :size="'lg'"
                          :text="$t('general.save').toUpperCase()"
                          class="update_button"
                          icon-name="icons.waybill.save"
                          @onClick="submitOrderCsr"
                      ></button-with-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="(currentActiveTab === 1 && isValidToContinueOnGeneral)" class="row py-7 px-3 items-start">
              <div class="col-12 text-right" v-if="id!=null">
                <button
                    class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer mr-2" v-if="!isDisableCalculateCosts"
                    @click="calculateCost">
                  {{ $t('order.calculate_cost') }}
                </button>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-3 col-sm-4">
                <date-picker-input
                    :disabled="true"
                    :is-inline="false"
                    :model="orderDate"
                    :required="false"
                    :title="$t('order.order_date')"
                    class="font-weight-boldest"
                    input-width="auto"
                ></date-picker-input>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-3 col-sm-4">
                <text-input
                    v-if="form && form.user && form.user.name"
                    :disabled="true"
                    :is-inline="false"
                    :model="form.user.name"
                    :required="false"
                    :title="$t('order.customer_representative')"
                    class="font-weight-boldest"
                ></text-input>
                <text-input
                    v-else
                    :disabled="true"
                    :is-inline="false"
                    :model="authUser.name"
                    :required="false"
                    :title="$t('order.customer_representative')"
                    class="font-weight-boldest"
                ></text-input>
              </div>
              <div class="col-xl-2 col-lg-1 col-md-2 col-sm-4" v-if="isVisibleSalePrice">
                <number-input
                    :helper-text="$t('order.please_enter_sale_price')"
                    :is-inline="false"
                    :model.sync="form.sale_price"
                    :required="false"
                    :step="0.01"
                    :title="$t('order.sale_price')"
                    class="font-weight-boldest"
                    icon-name="icons.sample_instruction.currency"
                ></number-input>
              </div>
              <div class="col-xl-2 col-lg-1 col-md-2 col-sm-4">
                <custom-multi-select
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.currency_unit_id"
                    :not-list="true"
                    :options="options.currencyOptions"
                    :placeholder="$t('order.unit')"
                    :required="false"
                    :title="$t('order.unit')"
                    input-width="100px"
                    name="order_unit_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 w-100">
                <custom-multi-select
                    :is-inline="false"
                    :is-right="true"
                    :item-per-row="1"
                    :max="1"
                    :model.sync="form.qc_user_id"
                    :not-list="true"
                    :options="options.qcUserOptions"
                    :required="false"
                    :title="$t('order.qc').toUpperCase()"
                    class="font-weight-boldest"
                    input-max-width="200px"
                    input-width="auto"
                    name="order_qc_staff"
                    width="175px"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0 ">
                <div class="col-12 mt-3">
                  <div class="d-flex justify-content-between font-weight-boldest mb-2" 
                    >
                    <span>{{ $t('order.export_date') }}</span>
                    <span class="cursor-pointer" @click="addExportDateInfo" v-html="getIconByKey('icons.model_house.add', {
                        class: 'w-20px h-20px d-inline-block object-cover'
                      })"></span>
                  </div>
                  <div class="navy-blue-border-1px px-4 py-2 border-15px">
                    <div v-for="(export_date, index) in form.order_export_dates"
                         class="row items-center md:bg-gray-200">
                      <div
                          :class="{
                            'col-xl-6 col-lg-6 col-md-6 col-sm-12': index === 0,
                            'col-xl-5 col-lg-5 col-md-5 col-sm-12': index > 0
                           }"
                          class="p-0 m-0"
                      >
                        <date-picker-input
                            :disabled="(index==0 && id && !isUserGranted('Staff', ['superAdmin'], false, true)) ? true : false" 
                            :is-inline="false"
                            :model.sync="export_date.export_date"
                            :title="$t('order.date')"
                        ></date-picker-input>
                      </div>
                      <div
                          :class="{
                            'col-xl-6 col-lg-6 col-md-6 col-sm-12': index === 0,
                            'col-xl-5 col-lg-5 col-md-5 col-sm-12': index > 0
                           }"
                          class="p-0 m-0"
                      >
                        <number-input
                            :is-inline="false"
                            :is-valid="isOrderExportDateAmountValid"
                            :model.sync="export_date.amount"
                            :placeholder="$t('order.please_enter_manually')"
                            :title="$t('order.amount')"
                        ></number-input>

                      </div>
                      <div v-if="index > 0" class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-0 m-0">
                        <span class="cursor-pointer d-inline" @click="deleteExportDateInfo(index)" v-html="getIconByKey('icons.sample.delete_red', {
                        class: 'w-20px h-20px d-inline-block object-cover'
                      })"></span>
                      </div>
                      <div class="col-1 pb-3 d-none">
                        <span v-html="getIconByKey('icons.sample.enter-product-name', {
                            class: 'w-15px h-15px  d-inline-block object-fill cursor-pointer'
                        })">
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0 ">
                <div class="col-12 mt-3">
                  <div class="font-weight-boldest mb-2">
                    {{ $t('order.description') }}
                  </div>
                  <div class="navy-blue-border-1px px-3 py-2 border-15px">
                    <textarea id=""
                              v-model="form.description" :placeholder="$t('order.please_enter_description')"
                              class="w-100 outline-none" name="order_description"
                              rows="2">
                    </textarea>
                    <div class="w-100 text-right">{{ $t('order.max') }}
                      {{ form.description !== null ? 300 - form.description.length : 300 }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0 ">
                <div class="col-12 mt-3">
                  <div class="font-weight-boldest mb-2">
                    {{ $t('order.description_for_instructions') }}
                  </div>
                  <div class="navy-blue-border-1px px-3 py-2 border-15px">
                    <textarea
                        v-model="form.instruction_description"
                        :placeholder="$t('order.please_enter_description')"
                        class="w-100 outline-none"
                        name="instruction_description"
                        rows="3">
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0 ">
                <div class="col-12 mt-3">
                  <div class="font-weight-boldest mb-2">
                    {{ $t('order.fabric_composition') }}
                  </div>
                  <div class="navy-blue-border-1px px-3 py-2 border-15px">
                    <textarea
                        v-model="form.fabric_composition"
                        :placeholder="$t('order.fabric_composition')"
                        class="w-100 outline-none"
                        name="fabric_composition"
                        :required="true"
                        :is-valid="isFabricCompositionValid"
                        rows="3">
                    </textarea>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0 ">
                <div class="col-12 mt-3">
                  <number-input
                      :helper-text="$t('order.please_enter')"
                      :is-inline="false"
                      :model.sync="form.amount_per_parcel"
                      :required="false"
                      :step="1"
                      :title="$t('order.amount_per_parcel')"
                      class="font-weight-boldest"
                  ></number-input>
                </div>
              </div>

              <div v-if="isValidToContinueOnFabric" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0 m-0">
                <div class="col-12 mt-3">
                  <div class="d-flex justify-content-between font-weight-boldest mb-2">
                    <span>{{ $t('order.fabric') }}</span>
                  </div>
                  <div class="navy-blue-border-1px px-4 py-2 border-15px">
                    <div v-for="(order_fabric, index) in form.order_fabrics"
                         class="row items-center md:bg-gray-200">
                      <div
                          class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0"
                      >
                        <date-picker-input
                            :is-inline="true"
                            :model.sync="order_fabric.deadline_start"
                            :title="$t('order.deadline_start')"
                            input-width="70px"

                        ></date-picker-input>
                      </div>
                      <div
                          class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0"
                      >
                        <date-picker-input
                            :is-inline="true"
                            :model.sync="order_fabric.deadline_end"
                            :title="$t('order.deadline_end')"
                            input-width="70px"
                        ></date-picker-input>
                      </div>
                      <div
                          class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0"
                      >
                        <custom-multi-select
                            :height="'200px'"
                            :is-inline="true"
                            :item-per-row="1"
                            :max-show="1"
                            :model.sync="order_fabric.selected_order_fabric_compositions"
                            :name="`order_selected_order_fabric_compositions-${index}`"
                            :options="options.fabricCompositionOptions"
                            :required="false"
                            :title="$t('order.compositions')"
                            :width="'200px'"
                            @save="onOrderFabricCompositionChange"
                        ></custom-multi-select>
                      </div>
                      <div class="col-1 pb-3 d-none">
                      </div>
                      <template v-if="order_fabric.order_fabric_compositions.length > 0">
                        <div
                            class="navy-blue-border-left-1px navy-blue-border-right-1px border-15px col-xl-3 col-lg-3 col-md-3 col-sm-12 p-0 m-0"
                            style="overflow-x: auto;">
                          <div class="col-12 row mx-0 ">
                            <div class="flex-grow-1 mx-0 pb-0">
                              <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th v-for="(fabric_composition, key) in order_fabric.order_fabric_compositions"
                                        class="text-center navy-blue-border-bottom-1px  navy-blue-border-top-1px  ">
                                      <div class="d-flex justify-content-center items-center">
                                      <span :for="`fabric_composition_amount-${key}`" class="d-inline-block px-5">{{
                                          options.fabricCompositionOptions[fabric_composition.fabric_composition_id]
                                        }}</span>
                                      </div>
                                    </th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr class="h-100">
                                    <td v-for="(fabric_composition, key) in order_fabric.order_fabric_compositions"
                                        class="navy-blue-border-right-1px">
                                      <div class="d-flex justify-content-center">
                                        <input :id="`fabric_composition_amount-${key}`"
                                               v-model="fabric_composition.amount"
                                               :class="{'is-invalid': Number(form.amount) < sizeAndColorTotal}"
                                               class="form-control form-control-sm"
                                               step="1"
                                               type="number">
                                      </div>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="isValidToContinueOnFabric">
                <div class="col-12 d-flex justify-content-between text-lg font-weight-boldest mt-5">
                  <span>{{ $t('order.fabric') }}</span>
                  <span class="cursor-pointer" @click="addNewOrderFabric"
                        v-html="getIconByKey('icons.model_house.add', {})"></span>
                </div>
                <div class="w-100 border-15px navy-blue-border-1px mx-3 mb-1">
                  <table class="table w-100 border-15px mb-0">
                    <thead class="w-100">
                    <tr>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.genus') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.width') }}(cm)
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.weight') }} (gr)
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.unit') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.pastal') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.unit_price') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.planned') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <!-- döviz cinsinden tutarı -->
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('sample.currency_type') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.currency_price') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('finance_payments.currency_total') }}
                          <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                          ></info-tooltip>
                        </div>
                      </th>
                       <!-- döviz cinsinden bitiş -->
                      <th class="navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t('order.actual_person') }}
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="navy-blue-bottom-1px">
                    <tr v-for="(order_fabric, index) in form.order_fabrics">
                      <td class="navy-blue-border-right-1px items-start">
                        <input v-model="order_fabric.type" class="form-control d-inline-block mb-5" type="text"
                        >
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input v-model="order_fabric.width"
                               class="form-control form-control-sm d-inline-block" step="1"
                               type="number"
                        >
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input v-model="order_fabric.weight" class="form-control form-control-sm"
                               step="1" type="number"
                        >
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <custom-multi-select
                            :input-width="`65px`"
                            :is-inline="false"
                            :item-per-row="1"
                            :max="1"
                            :model.sync="order_fabric.unit_id"
                            :name="`order_fabric_unit_id_${index}`"
                            :not-list="true"
                            :options="options.unitOptions2"
                            :placeholder="$t('order.please_enter_manually')"
                            :required="false"
                            :title="null"
                        ></custom-multi-select>
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input :disabled="isDisablePriceEdit" v-model="order_fabric.pastal" class="form-control form-control-sm"
                               type="number"
                        >
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input :disabled="isDisablePriceEdit" v-model="order_fabric.unit_price" class="form-control form-control-sm"
                                type="number"
                        >
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input :disabled="true" :value="Number(order_fabric.pastal) * Number(order_fabric.unit_price)"
                               class="form-control form-control-sm" step="0.01"
                               type="number">
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <custom-multi-select
                            class="font-weight-bolder pb-0 mb-0"
                            @save="onCurrencyFabricChange(index)"
                            :title="''"
                            :options="options.currencyRateOptions"
                            :model.sync="order_fabric.currency_unit_id"
                            object-key="code"
                            name="currency_unit"
                            :max="1"
                            :min="1"
                            :not-list="true"
                            :has-bottom-margin="false"
                            :is-inline="false"
                            :required="false"
                        ></custom-multi-select>
                        <div class="display-block text-center mt-0 pt-0" 
                        v-if="order_fabric.currency_unit_id">
                        <input
                            type="text"
                            class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                            :placeholder="$t('esc.new_hand_enter')"
                            v-model="order_fabric.exchange_rate"
                          />
                        </div>
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input :disabled="isDisablePriceEdit" v-model="order_fabric.currency_price" class="form-control form-control-sm"
                                type="number"
                        >
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input :disabled="true" v-model="order_fabric.currency_price_total" class="form-control form-control-sm"
                        type="number"
                        >
                      </td>
                      <td class="">
                        <span class="mr-2"> {{ order_fabric.actual }} </span>
                        <a href="#" @click.prevent="showWaybills(order_fabric.id, 1, order_fabric.type)">
                          <i v-if="id"
                             class="fa fa-eye"></i></a>
                        <div v-if="!order_fabric.actual" class="w40 items-end d-flex justify-content-end">
                        <span class="cursor-pointer d-inline" @click="deleteNewOrderFabric(index)" v-html="getIconByKey('icons.sample.delete_red', {
                        class: 'w-20px h-20px d-inline-block object-cover'
                      })"></span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="w-100 row justify-content-end">
                  <div
                      class="px-2 text-xl font-weight-boldest navy-blue-border-1px border-10px py-2">
                    <span class="">{{ $t('order.total').toUpperCase() }} : </span>
                    <span>{{ fabricTotalAmount }}</span>
                  </div>
                </div>
              </template>

              <template v-if="isValidToContinueOnExpenses">
                <div class="row w-100 mx-3 navy-blue-border-1px border-15px py-5 px-3 mt-5 items-start">
                  <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 row mx-0 justify-content-between navy-blue-border-right-1px md-border-r-0">
                    <div class="col-12 row mx-0 justify-content-between mb-5">
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.expenses').toUpperCase() }}</span></div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.planned_amount') }}</span></div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.actual_amount') }}</span></div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.amount') }}</span></div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.unit') }}</span></div>
                    </div>
                    <template v-for="(expense, index) in form.order_expenses">
                      <div v-if="expense.is_default === 1" class="col-12 row mx-0 justify-content-between py-2"
                           style="border-bottom: 1px solid rgba(100, 100, 100, 0.25);">
                        <div class="text-lg font-weight-bold" style="flex: 1;">
                          <i class="fa fa-eye" role="button"
                             @click.prevent="showWaybills(expense.expense.id, type = 2, expense.name)">
                          </i>
                          {{ expense.name }}
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <input :disabled="isDisablePriceEdit" v-model="expense.planned" :placeholder="$t('order.please_enter_manually')"
                                 class="form-control form-control-xs font-weight-bold"
                                 type="text">
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <input v-model="expense.actual"
                                 :class="{'text-primary': Number(expense.actual) <= Number(expense.planned), 'text-danger': Number(expense.actual) > Number(expense.planned)}"
                                 :disabled="true"
                                 :placeholder="$t('order.please_enter_manually')"
                                 class="form-control form-control-xs font-weight-bold"
                                 type="text">
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <input v-model="expense.amount" :placeholder="$t('order.please_enter_manually')"
                                 class="form-control form-control-xs"
                                 type="text">
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <select v-model="expense.unit_id" class="form-control form-control-xs" name="">
                            <option value="">{{ $t('general.please_select_option') }}</option>
                            <option v-for="(unit, unit_key) in options.unitOptions" :value="unit_key">{{
                                unit
                              }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 row mx-0 py-lg-0 py-md-4 justify-content-between items-start">
                    <div class="col-12 row mx-0 justify-content-between mb-5">
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.expenses').toUpperCase() }} </span>
                      </div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.planned_amount') }} </span></div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.actual_amount') }}</span></div>
                      <div class="text-lg font-weight-bold text-center" style="flex: 1;"><span
                          class="navy-blue-border-bottom-1px">{{ $t('order.amount') }}</span></div>
                      <div class="text-lg font-weight-bold text-center relative" style="flex: 1;">
                        <span
                            class="navy-blue-border-bottom-1px">{{ $t('order.unit') }}
                          <span class="d-inline absolute" style="right: 0;">
                          <CustomMultiSelect
                              :is-right="true"
                              :model.sync="form.selected_expenses"
                              :name="$t('esc.new_please_select')"
                              :options="options.expensesOptions"
                              :required="true"
                              :title="null"
                              class="w-100"
                              object-key="name"
                              width="300px"
                              @save="onExpensesSave"
                          >
                            <template slot="inputDesign">
                            <span class="items-center" v-html="getIconByKey('icons.waybill.waybill_entry', {
                                class: 'w-20px h-20px  object-fill flex-grow-1 cursor-pointer'
                              })">
                            </span>
                            </template>
                          </CustomMultiSelect>
                        </span>
                        </span>
                      </div>
                    </div>

                    <template v-for="(expense, index) in form.order_expenses">
                      <div v-if="expense.is_default === 0"
                           class="col-12 row mx-0 justify-content-between py-2 items-start"
                           style="border-bottom: 1px solid rgba(100, 100, 100, 0.25);">
                        <div class="text-lg font-weight-bold" style="flex: 1;">
                          <i class="fa fa-eye" role="button"
                             @click.prevent="showWaybills(expense.expense.id, type = 2, expense.name)">
                          </i>
                          {{ expense.name }}
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <input :disabled="isDisablePriceEdit" v-model="expense.planned" :placeholder="$t('order.please_enter_manually')"
                                 class="form-control form-control-xs font-weight-bold"
                                 type="text">
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <input v-model="expense.actual"
                                 :class="{'text-primary': Number(expense.actual) <= Number(expense.planned), 'text-danger': Number(expense.actual) > Number(expense.planned)}"
                                 :disabled="true"
                                 :placeholder="$t('order.please_enter_manually')"
                                 class="form-control form-control-xs font-weight-bold"
                                 type="text">
                        </div>
                        <div class="text-lg font-weight-bold px-3" style="flex: 1;">
                          <input v-model="expense.amount" :placeholder="$t('order.please_enter_manually')"
                                 class="form-control form-control-xs"
                                 type="text">
                        </div>
                        <div class="text-lg d-flex justify-content-end font-weight-bold px-3" style="flex: 1;">
                          <select v-model="expense.unit_id" class="form-control form-control-xs" name="">
                            <option value="">{{ $t('general.please_select_option') }}</option>
                            <option v-for="(unit, unit_key) in options.unitOptions" :value="unit_key">{{
                                unit
                              }}
                            </option>
                          </select>
                          <span v-if="expense.is_default === 0 " class="items-center d-inline-block"
                                @click="DeleteExpensesSave(index)" v-html="getIconByKey('icons.sample.delete_red', {
                                class: 'w-20px h-20px  object-fill flex-grow-1 cursor-pointer'
                              })">
                            </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="w-100 row justify-content-end mt-1">
                  <div
                      class="px-2 text-xl font-weight-boldest navy-blue-border-1px border-10px py-3">
                    <span class="">{{ $t('order.total').toUpperCase() }} : </span>
                    <span>{{ expenseTotalAmount }}</span>
                  </div>
                </div>
                <div class="w-100 row justify-content-end mt-1">
                  <div
                      class="px-2 text-xl font-weight-boldest primary-border-2px border-10px py-3 text-primary">
                    <span class="">{{ $t('order.general_total').toUpperCase() }} : </span>
                    <span> {{ (Number(fabricTotalAmount) + Number(expenseTotalAmount)).toFixed(2) }}</span>
                  </div>
                </div>
              </template>
              <template v-if="isValidToContinueOnSizeAndColor">
                
                <div class="col-12 text-lg font-weight-boldest mt-5">
                  {{ $t('order.size_and_color') }}
                </div>
                <div class="col-12 text-lg d-flex justify-content-end">
                      <span class="cursor-pointer" @click="addNewSizeAndColor" v-html="getIconByKey('icons.waybill.waybill_entry',{
                        class: 'w-35px h-35px d-inline-block mr-3'
                      })"></span>
                 </div>
                <div class="w-100 row navy-blue-border-1px mx-3 border-15px" style="overflow-x: auto;">
                  <div class="col-12 row mx-0 navy-blue-border-bottom-1px">
                    <div class="w-250px"></div>
                    <div class="flex-grow-1 text-center text-lg font-weight-bold py-1"
                         style="min-width: 150px !important; max-width: calc(100% - 500px);">
                      {{ $t('order.sizes').toUpperCase() }}
                    </div>
                    <div class="w-100px"></div>
                    <div class="w-100px"></div>
                  </div>
                  
                  <div v-for="(size_and_color, index) in form.order_size_and_colors"
                       :key="index" class="col-12 row mx-0 navy-blue-border-bottom-1px">
                    <div class="w-300px row mx-0 py-3 navy-blue-border-right-1px">
                      <div class="col-6 text-lg font-weight-bold ">
                        <div>{{ $t('order.color_name') }}</div>
                        <input v-model="size_and_color.color_name" :placeholder="$t('order.color_name')"
                               class="form-control form-control-sm"
                               step="1" type="text">
                      </div>
                      <div class="col-6 text-lg font-weight-bold">
                        <div>{{ $t('order.color_number') }}</div>
                        <input v-model="size_and_color.color_number" :placeholder="$t('order.color_number')"
                               class="form-control form-control-sm"
                               step="1" type="number">
                      </div>
                      <div class="col-12 d-flex justify-content-center mt-5"
                      v-if="form.order_size_and_colors[index]"
                      >
                        <span class="fas fa-truck fa-lg d-inline-block mr-3"  
                        :style="{ color: getOrderSizeExport(index) ? '#5D9C59' : '#F24C3D' }"
                        @click="openExportDatePopup(index)"
                        ></span>
                        <span class="cursor-pointer" v-if="size_and_color.instruction_check" @click="resetSizeAndColor(index)" v-html="getIconByKey('icons.order.reset-item',{
                          class: 'w-20px h-20px d-inline-block mr-3' 
                        })"></span>
                          <span v-if="form.order_size_and_colors.length>1 && size_and_color.instruction_check" class="cursor-pointer" @click="deleteNewSizeAndColor(index)" v-html="getIconByKey('icons.sample.delete_red',{
                          class: 'w-20px h-20px d-inline-block mr-3 '
                        })"></span>
                        
                      </div>
                    </div>
                    <div class="flex-grow-1 mx-0" style="min-width: 150px !important; max-width: calc(100% - 500px);">
                      <div class="table-responsive h-100">
                        <table class="table h-100">
                          <thead>
                          <tr>
                            <template v-for="defaultSize in sortBodySizeDefault">
                              <th v-if="size_and_color.body_sizes[defaultSize.body_size_id]" :key="index + '_' + defaultSize.body_size_id"
                                class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                              <div class="w-100 d-flex justify-content-center items-center">
                                <span class="d-inline-block px-5">{{ size_and_color.body_sizes[defaultSize.body_size_id].name }}</span>
                                <span v-if="Object.keys(size_and_color.body_sizes).length > 1 && size_and_color.instruction_check"
                                      class="float-right cursor-pointer" @click="removeSizeFromList(index, defaultSize.body_size_id)"
                                      v-html="getIconByKey('icons.waybill.waybill_exit', {})"></span>
                              </div>
                            </th>
                            </template>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                             <template v-for="defaultSize in sortBodySizeDefault">
                              <td v-if="size_and_color.body_sizes[defaultSize.body_size_id]" class="navy-blue-border-right-1px">
                                <div class="d-flex justify-content-center">
                                  <input v-model="size_and_color.body_sizes[defaultSize.body_size_id].amount"
                                         :class="{'is-invalid': Number(form.amount) < sizeAndColorTotal}"
                                         class="form-control form-control-sm" type="number">
                                </div>
                              </td>
                             </template>
                           
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="w-100px row mx-0 py-3 navy-blue-border-right-1px">
                      <div class="col-12 text-lg font-weight-bold">
                        <div class="mb-3">{{ $t('order.total').toUpperCase() }}</div>
                        <h3 class="text-center py-3">{{ sizeAndColorTotalByIndex(index) }}</h3>

                        <template
                            v-if="index === form.order_size_and_colors.length - 1 && isValidToSubmit">
                          <button-with-icon
                              v-if="!id"
                              :disabled="is_submitting || !isUserGranted('Order', ['create'], false)"
                              :icon-name="null"
                              :text="$t('order.save').toUpperCase()"
                              size="md"
                              @onClick="submitForm"
                          ></button-with-icon>
                          <button-with-icon
                              v-else
                              :disabled="is_submitting || !isUserGranted('Order', ['update'], false)"
                              :icon-name="null"
                              :text="$t('order.update').toUpperCase()"
                              size="md"
                              @onClick="updateOrder"
                          ></button-with-icon>
                        </template>
                      </div>
                    </div>
                    <div class="w-100px row mx-0 py-3">
                      <div class="col-12 text-lg font-weight-bold">
                        <div class="mb-3">{{ $t('order.export_total').toUpperCase() }}</div>
                        <h3>{{ size_and_color.export_total ? Number(size_and_color.export_total) : 0 }} </h3>
                      </div>
                    </div>
                  </div>

                  <template v-if="id">
                    <div class="col-12 d-flex justify-content-between text-lg font-weight-boldest mt-5">
                      <span>{{ $t('order.fabric_requirements').toUpperCase() }}</span>
                    </div>
                    <div class="w-100 border-15px navy-blue-border-1px mx-3 mb-1">
                      <table class="table w-100 border-15px mb-0">
                        <thead class="w-100">
                        <tr>
                          <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                            <div class="d-flex justify-content-center items-center text-lg">
                              {{ $t('order.fabric') }}
                            </div>
                          </th>
                          <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                            <div class="d-flex justify-content-center items-center text-lg">
                              {{ $t('order.fabric_color') }}
                            </div>
                          </th>
                          <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                            <div class="d-flex justify-content-center items-center text-lg">
                              {{ $t('order.fabric_requirement_order') }}
                            </div>
                          </th>

                          <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                            <div class="d-flex justify-content-center items-center text-lg">
                              {{ $t('order.fabric_requirement_extra') }}
                            </div>
                          </th>

                          <th class="navy-blue-border-bottom-1px">
                            <div class="d-flex justify-content-center items-center text-lg">
                              {{ $t('order.fabric_requirement_total') }}
                            </div>
                          </th>
                        </tr>
                        </thead>
                        <tbody class="navy-blue-bottom-1px">
                        <tr v-for="order_fabric_requirement in form.order_fabric_requirements">
                          <td class="navy-blue-border-right-1px items-start">
                            <a href="#"
                               @click.prevent="showWaybills(order_fabric_requirement, 3, order_fabric_requirement.order_fabric.type +'('+  order_fabric_requirement.order_size_and_color.color_name + '-' + order_fabric_requirement.order_size_and_color.color_number + ')')"><i
                                class="fa fa-eye"></i></a>

                            <span> {{ order_fabric_requirement.order_fabric.type }}</span>
                          </td>
                          <td class="navy-blue-border-right-1px">
                            <span> {{
                                order_fabric_requirement.order_size_and_color.color_name
                              }} - {{ order_fabric_requirement.order_size_and_color.color_number }}</span>
                          </td>
                          <td class="navy-blue-border-right-1px">
                            <span>{{ order_fabric_requirement.order_amount }}</span>
                          </td>
                          <td class="navy-blue-border-right-1px">
                            <span>
                              <input v-model="order_fabric_requirement.extra_amount" :disabled="isDisableUpdateExtraAmount"
                                     class="form-control form-control-sm" type="number">
                            </span>
                          </td>
                          <td class="navy-blue-border-right-1px">
                            <span>{{ order_fabric_requirement.amount }}</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </div>
              </template>

              <template v-if="form.is_submitted">
                <div v-if="currentActiveTab === 1" class="w-100 d-flex justify-content-center items-center mt-5">
                  <div class="d-flex items-center cursor-pointer" @click="currentActiveTab = 2">
                    <span
                        class="text-2xl font-weight-boldest text-primary">{{
                        $t('order.suppliers_and_manufacturer').toUpperCase()
                      }}</span>
                    <span v-html="getIconByKey('icons.order.continue', {
                  class: 'w-40px h-40px d-inline-block ml-5'
                })"></span>
                  </div>
                </div>
              </template>
            </div>

            <div v-if="currentActiveTab === 2" class="row py-7">
              <div class="w-100 mx-0">
                <div class="w-100 mb-5">
                  <div class="d-flex px-10">
                    <div
                        :class="{'bg-primary text-white custom-tab-box': activeTab === 1}"
                        class="w-200px text-center px-5 py-3 text-xl font-weight-bold primary-border-1px border-radius-left-top-10px border-radius-left-bottom-10px cursor-pointer"
                        @click="activeTab = 1">
                      {{ $t('order.expenses').toUpperCase() }}
                    </div>
                    <div
                        :class="{'bg-primary text-white custom-tab-box': activeTab === 2}"
                        class="w-200px text-center px-5 py-3 text-xl font-weight-bold primary-border-1px border-radius-right-top-10px border-radius-right-bottom-10px cursor-pointer"
                        @click="activeTab = 2">
                      {{ $t('order.fabrics').toUpperCase() }}
                    </div>
                  </div>
                </div>
                <template v-if="+activeTab === 1">
                  <div class="w-100 row mx-0 px-10 mb-5">
                    <div v-for="(expense_type, index) in form.order_expenses" class="">
                    <span
                        :class="{'bottom-border-2px-primary': Number(expense_data.selectedExpenseID) === Number(expense_type.expense_id)}"
                        class="text-lg font-weight-bold px-3 pt-3 pb-1 cursor-pointer"
                        @click="changeSelectedExpense(expense_type)">{{
                        expense_type.name
                      }}</span>
                    </div>
                  </div>
                  <template v-if="expense_data.selectedExpenseID && expense_details[expense_data.selectedExpenseID]">
                    <template
                        v-for="(expenseDetail, expend_detail_index) in expense_details[expense_data.selectedExpenseID].order_expense_details">
                      <div :class="{'mt-10': expend_detail_index > 0}" class="w-100 mx-0 px-3 " :ref="`expense_${expenseDetail.id}`" :id="`expense_${expenseDetail.id}`">
                        <div class="w-100 navy-blue-border-1px border-15px mb-5">
                          <div class="d-flex navy-blue-border-bottom-1px px-2 py-2">
                            <span class="h6 text-sm mr-2">{{$t('general.created_at')}}:</span><span class="text-sm">{{expenseDetail.created_at?moment(expenseDetail.created_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
                            
                            <span class="mx-1 h25"> / </span>
                            <span class="h6 text-sm mr-2">
                              {{$t('general.updated_date')}}:</span><span class="text-sm">{{expenseDetail.updated_at?moment(expenseDetail.updated_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
                            
                         </div>
                          <table class="table mb-0">
                            <tr>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                {{ $t('order.instruction_type') }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">{{
                                  $t('order.expense')
                                }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">{{
                                  $t('order.supplier')
                                }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                {{ $t('order.dispatch_location') }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                {{ $t('order.currency_unit') }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">{{
                                  $t('order.date')
                                }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">{{
                                  $t('order.who_took_the_order')
                                }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">{{
                                  $t('order.payment')
                                }}
                              </th>
                              <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">{{
                                  $t('order.start_date')
                                }}
                              </th>
                              <th class="navy-blue-border-bottom-1px">{{ $t('order.end_date') }}</th>
                            </tr>
                            <tbody>
                            <tr>
                              <td class="navy-blue-border-right-1px">{{ $t('order.expense') }}
                                {{
                                  expense_data.selectedExpenseID in options.expensesOptions ? options.expensesOptions[expense_data.selectedExpenseID].name : options.optionalExpenseOptions[expense_data.selectedExpenseID].name
                                }}
                              </td>
                              <td class="navy-blue-border-right-1px">
                                {{
                                  expense_data.selectedExpenseID in options.expensesOptions ? options.expensesOptions[expense_data.selectedExpenseID].name : options.optionalExpenseOptions[expense_data.selectedExpenseID].name
                                }}
                              </td>
                              <td class="navy-blue-border-right-1px">
                                <custom-multi-select
                                    :item-per-row="1"
                                    :max="1"
                                    :max-show="1"
                                    :model.sync="expenseDetail.supplier_company_id"
                                    :not-list="true"
                                    :options="options.supplierCompanyOptions"
                                    :required="false"
                                    :title="null"
                                    name="supplier_company"
                                ></custom-multi-select>
                              </td>
                              <td class="navy-blue-border-right-1px">
                                <custom-multi-select
                                    :item-per-row="1"
                                    :max="1"
                                    :max-show="1"
                                    :model.sync="expenseDetail.dispatch_location_id"
                                    :not-list="true"
                                    :options="options.dispatchLocationOptions"
                                    :required="false"
                                    :title="null"
                                    name="dispatch_location"
                                ></custom-multi-select>
                              </td>
                              <td class="navy-blue-border-right-1px">
                                <div>
                                  <custom-multi-select
                                      :max="1"
                                      :max-show="1"
                                      :model.sync="expenseDetail.currency_unit_id"
                                      :name="`expense_detail_currency-${expend_detail_index}`"
                                      :not-list="true"
                                      :options="options.currencyOptions"
                                      :required="false"
                                      :title="null"
                                      @save="onExpenseDetailCurrencyChange"
                                  ></custom-multi-select>
                                </div>
                                <div>
                                  <b>{{ $t('general.exchange_rate') }}:</b> {{ expenseDetail.exchange_rate }}
                                </div>
                              </td>
                              <td class="navy-blue-border-right-1px">
                                {{ expenseDetail.date || today | laravelToMomentTimeFormat }}
                              </td>
                              <td class="navy-blue-border-right-1px">
                                <text-input
                                    :model.sync="expenseDetail.to_who"
                                    :placeholder="$t('order.who_took_the_order')"
                                    :required="false"
                                    name="to_who"
                                ></text-input>
                              </td>
                              <td class="navy-blue-border-right-1px">
                                <custom-multi-select
                                    :max="1"
                                    :model.sync="expenseDetail.expiry_id"
                                    :not-list="true"
                                    :options="options.expirationTypeOptions"
                                    :required="false"
                                    :show-invalid="true"
                                    :title="null"
                                    name="expiration_type_options"
                                    object-key="name"
                                ></custom-multi-select>
                              <td class="navy-blue-border-right-1px">
                                <date-picker-input
                                    :model.sync="expenseDetail.start_date"
                                    :required="false"
                                    :title="null"
                                    input-width="85px"
                                ></date-picker-input>
                              </td>
                              <td>
                                <date-picker-input
                                    :model.sync="expenseDetail.end_date"
                                    :required="false"
                                    :title="null"
                                    input-width="85px"
                                ></date-picker-input>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="w-100 mx-0 px-3">
                        <div class="w-100 row mx-0 border-15px navy-blue-border-1px">
                          <div class="col-12 row mx-0 navy-blue-border-bottom-1px">
                            <div v-if="Number(expenseDetail.expense_type_id) === 2"

                                 :class="Number(expense_data.selectedExpenseID) === 17 ? 'col-4' : 'col-5'"
                                 class="row w-250px mx-0 navy-blue-border-right-1px">
                              <div class="col-12 text-lx font-weight-bold">
                                {{ $t('order.description') }}
                              </div>
                              <div class="col-12 pb-3">
                              <textarea v-model="expenseDetail.description" class="w-100 form-control px-0" name=""
                                        rows="2">
                              </textarea>
                              </div>
                            </div>
                            <div
                                :class="{'w-150px': +expenseDetail.expense_type_id !== 2, 'w-100px': +expenseDetail.expense_type_id === 2}"
                                class="row col-2 mx-0 py-3 navy-blue-border-right-1px">
                              <div
                                  class="col-12 text-lg font-weight-bold">
                                <div class="col-12 mx-0">
                                  <div class="col-12 text-lg font-weight-bold py-3 ">
                                    <div class="mb-3">{{ $t('order.color_name') }}</div>
                                    <custom-multi-select
                                        :has-bottom-margin="false"
                                        :is-inline="false"
                                        :item-per-row="1"
                                        :max="1"
                                        :model.sync="expenseDetail.order_size_and_color_id"
                                        :name="`expense_detail_unit_id_${expend_detail_index}`"
                                        :not-list="true"
                                        :options="sizeAndColorOptions"
                                        :placeholder="$t('order.color_name')"
                                        :required="false"
                                        :title="null"
                                        input-width="100px"
                                        width="200px"
                                        @onSelect="onChangeSizeAndColor($event, expenseDetail)"
                                        @save="expenseDetail.body_sizes =  null"
                                    ></custom-multi-select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="+expenseDetail.expense_type_id !== 2"
                                 :class="!expenseDetail.body_sizes ? 'd-flex justify-content-center align-items-center text-center' : ''"
                                 class="col-6 mx-0"
                                 style="min-width: 150px !important; max-width: calc(100% - 725px);">
                              <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th v-for="(value, key) in expenseDetail.body_sizes"
                                        :key="'size_and_color_body_size_name' + key"
                                        class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                      <div class="w-100 d-flex justify-content-center items-center">
                                        <span class="d-inline-block px-5">{{ value.name }} <span
                                            v-if="defaultAmount(expenseDetail, value, 2)">({{
                                            defaultAmount(expenseDetail, value, 2)
                                          }})</span></span>
                                      </div>
                                    </th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr class="h-100">
                                    <td v-for="(value, key) in expenseDetail.body_sizes"
                                        :key="'size_and_color_body_size_name' + key"
                                        class="navy-blue-border-right-1px">
                                      <div class="d-flex justify-content-center">
                                        <input v-model="value.amount" class="form-control form-control-sm min-width-50"
                                               type="text">
                                      </div>
                                    </td>
                                    <td v-if="!expenseDetail.body_sizes" class="border-top-0">Renk Seçimi Yapınız</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div v-if="+expenseDetail.expense_type_id !== 2"
                                 class="col-1 row mx-0 py-3 navy-blue-border-right-1px px-0">
                              <div class="col-12 text-lg font-weight-bold">
                                <div class="mb-3 text-nowrap">{{ $t('order.total').toUpperCase() }}</div>
                                <div class="text-center py-2 text-lg font-weight-boldest">
                                  {{ totalSizeAndColor(expenseDetail) }}
                                </div>
                              </div>
                            </div>
                            <div v-if="Number(expenseDetail.expense_type_id) === 2"
                                 class="col-1 row mx-0 py-3 navy-blue-border-right-1px px-0">
                              <div class="col-12 text-lg font-weight-bold">
                                <div class="mb-3">{{ $t('order.amount') }}</div>
                                <input v-model="expenseDetail.total" class="form-control form-control-xs" step="1"
                                       type="number">
                              </div>
                            </div>
                            <div class="col-1 row mx-0 text-center p-0">
                              <div class="col-12 text-lg font-weight-bold navy-blue-border-right-1px py-3  px-0">
                                <div class="mb-3">{{ $t('order.unit') }}</div>
                                <custom-multi-select
                                    :has-bottom-margin="false"
                                    :is-inline="false"
                                    :item-per-row="1"
                                    :max="1"
                                    :model.sync="expenseDetail.unit_id"
                                    :name="`expense_detail_unit_id_${expend_detail_index}`"
                                    :not-list="true"
                                    :options="options.unitOptions"
                                    :placeholder="$t('order.unit')"
                                    :required="false"
                                    :title="null"
                                    input-width="75px"
                                    width="200px"
                                ></custom-multi-select>
                              </div>
                            </div>
                            <div class="col-1 row mx-0 text-center p-0">
                              <div class="col-12 text-lg font-weight-bold navy-blue-border-right-1px py-3 px-0">
                                <div class="mb-3">{{ $t('order.price') }}</div>
                                <input v-model="expenseDetail.price" :placeholder="$t('order.please_enter_manually')"
                                       class="form-control form-control-sm"
                                       step="1" type="number">
                              </div>
                            </div>
                            <div class="col-1 row mx-0  text-center p-0">
                              <div class="col-12 text-lg font-weight-bold navy-blue-border-right-1px py-3 px-0">
                                <div class="mb-3">{{ $t('order.discount') }}</div>
                                <input v-model="expenseDetail.discount"
                                       :placeholder="$t('order.please_enter_manually')"
                                       class="form-control form-control-sm" step="1" type="number">
                              </div>
                            </div>
                            <div class="col-1 row mx-0 py-3 text-center p-0">
                              <div class="col-12 text-lg font-weight-bold">
                                <div class="mb-3">{{ $t('order.kdv_rate') }}</div>
                                <custom-multi-select
                                    :is-inline="false"
                                    :is-right="true"
                                    :max="1"
                                    :model.sync="expenseDetail.vat_rate_id"
                                    :not-list="true"
                                    :options="vatRateOptions"
                                    :required="false"
                                    :title="null"
                                    input-width="75px"
                                    name="vat_rate"
                                    width="200px"
                                ></custom-multi-select>
                              </div>
                            </div>
                            <div v-if="Number(expense_data.selectedExpenseID) === 17" class="col-1 row mx-0 py-3 text-center p-0">
                              <div class="col-12 text-lg font-weight-bold">
                                <div class="mb-3">{{ $t('order.snap') }}</div>
                                <custom-multi-select
                                    :item-per-row="1"
                                    :required="false"
                                    :max="1"
                                    :model.sync="expenseDetail.snap_type_id"
                                    :name="'snap_type_id'"
                                    :not-list="true"
                                    :options="options.snapTypeOptions"
                                    :title="null"
                                ></custom-multi-select>
                              </div>
                            </div>
                          </div>
                          <template v-if="Number(expenseDetail.expense_type_id) !== 2">
                            <div class="w-100 row mx-0">
                              <div
                                  :class="{'col-12': Number(expense_data.selectedExpenseID) !== 17, 'col-8': Number(expense_data.selectedExpenseID) === 17}"
                                  class="px-0">
                                <div class="col-12 text-lx font-weight-bold">
                                  {{ $t('order.description') }}
                                </div>
                                <div class="col-12 pb-3 navy-blue-border-bottom-1px">
                                <textarea v-model="expenseDetail.description" class="w-100 form-control" name=""
                                          rows="3"></textarea>
                                </div>
                              </div>
                              <div v-if="Number(expense_data.selectedExpenseID) === 17" class="col-4 row mx-0 px-0">
                                <div class="col-12 text-center text-lx font-weight-bold ">
                                  {{ $t('order.snap') }}
                                </div>
                                <div class="col-12 pb-3 navy-blue-border-bottom-1px d-flex justify-content-center">
                                  <div class="w-200px">
                                    <custom-multi-select
                                        :item-per-row="1"
                                        :max="1"
                                        :model.sync="expenseDetail.snap_type_id"
                                        :name="'snap_type_id'"
                                        :not-list="true"
                                        :options="options.snapTypeOptions"
                                        :title="null"
                                    ></custom-multi-select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template>
                            <div class="col-1 navy-blue-border-bottom-1px"></div>

                            <div class="col-11 text-center text-lg font-weight-bold py-2 navy-blue-border-bottom-1px">
                              {{ $t("order.order_production_goals") }}
                              <span
                                  class="float-right"
                                  @click="addNewTarget(expend_detail_index)"
                                  v-html="getIconByKey('icons.waybill.waybill_entry', {
                                class: 'w-35px h-35px d-inline-block object-cover',
                              })"
                              ></span>
                            </div>

                            <div v-for="(target, target_index) in expenseDetail.order_expense_detail_targets"
                                 :key="'targets_' + expend_detail_index + target_index"
                                 class="col-2 row mx-0 px-0"> 
                              <div
                                  :class="{

                              }"
                                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row mx-0 justify-content-center text-center navy-blue-border-right-1px py-2"
                              >
                                <div class="w-150px">
                                  {{ $t('order.date') }} / {{ $t('order.target') }}
                                  <date-picker-input
                                      :model.sync="target.date"
                                      :title="null"
                                      name="order_expense_detail_targets_date"
                                  ></date-picker-input>
                                  <number-input
                                      :model.sync="target.target"
                                      :required="false"
                                      :title="null"
                                      name="order_expense_detail_targets_target"
                                  ></number-input>
                                </div>
                                <span
                                    v-if="target_index > 0&&isVisibleDeleteTarget(target.date)"
                                    class="float-right"
                                    @click="deleteNewTarget(expend_detail_index, target_index)"
                                    v-html="getIconByKey('icons.sample.delete_red', {
                                  class: 'w-20px h-20px d-inline-block object-cover',
                                })"
                                ></span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <template v-if="expenseDetail">
                        <div
                            v-if="expenseDetail.is_editing"
                            class="w-100 d-flex justify-content-center py-3 my-10">
                          <button-with-icon
                              :disabled="!(isUserGranted('OrderExpenseDetail', ['create'], false) || isUserGranted('OrderExpenseDetail', ['update'], false))"
                              :icon-name="null"
                              :text="$t('order.create_instruction').toUpperCase()"
                              class="mx-2 text-primary"
                              size="xl"
                              @onClick="createOrUpdateInstruction(expend_detail_index)"
                          ></button-with-icon>
                        </div>
                        <div
                            v-if="expenseDetail.is_submitted && !expenseDetail.is_editing"
                            class="w-100 d-flex justify-content-center py-3">
                          <button-with-icon
                              :icon-name="null"
                              :text="$t('order.download_as_pdf').toUpperCase()"
                              class="mx-2"
                              size="lg"
                              @onClick="openInNewTab(expenseDetail.order_pdf_link)"
                          ></button-with-icon>
                          <button-with-icon
                              :icon-name="null"
                              :text="$t('order.download_as_excel').toUpperCase()"
                              class="mx-2"
                              size="lg"
                              @onClick="openInNewTab(expenseDetail.order_excel_link)"
                          ></button-with-icon>
                          <button-with-icon
                              :icon-name="null"
                              :text="$t('order.edit').toUpperCase()"
                              class="mx-2"
                              size="lg"
                              @onClick="changeToEditMode(expenseDetail, expend_detail_index)"
                          ></button-with-icon>
                          <button-with-icon
                              :icon-name="null"
                              :text="$t('order.cancel_instruction').toUpperCase()"
                              class="mx-2"
                              size="lg"
                              @onClick="cancelExpenseDetail(expenseDetail, expend_detail_index)"
                          ></button-with-icon>
                          <button-with-icon
                              :icon-name="null"
                              :text="$t('order.send').toUpperCase()"
                              class="mx-2"
                              size="lg"
                              @onClick="sendExpenseDetail(expenseDetail, expend_detail_index)"

                          ></button-with-icon>
                        </div>
                      </template>

                    </template>
                    <div class="w-100 px-5 py-2">
                      <span class="cursor-pointer" @click="addNewExpenseDetail" v-html="getIconByKey('icons.waybill.waybill_entry', {class: 'w-45px h-45px d-inline-block object-cover'
                      })"></span>
                      <span v-if="expense_details[expense_data.selectedExpenseID].order_expense_details.length > 1"
                            class="cursor-pointer"
                            @click="deleteNewExpenseDetail(expense_details[expense_data.selectedExpenseID].order_expense_details)"
                            v-html="getIconByKey('icons.sample.delete_red', {
                        class: 'w-20px h-20px d-inline-block object-cover'
                      })"></span>
                    </div>

                  </template>
                </template>
                <template v-if="+activeTab === 2 && (form.fabric_supply_type_id !== -1)">
                  <order-fabric
                      :options="options"
                      :order="form"
                      :order-id="form.id"
                  ></order-fabric>
                </template>
              </div>
            </div>

            <div v-if="currentActiveTab === 3" class="row py-7">
                <order-reports
                @selectReport="selectReport"
                >
                <template v-slot:reporttype>
                  <Transition name="cut">
                    <CutReport 
                      :order="form"
                      :order-id="form.id"
                      :order-report-data="orderReportData"
                      @closeCutReport="selectReportId=null"
                      v-if="selectReportId=='cutReport'"
                      >
                    </CutReport>
                  </Transition> 
                  <second-quality-table
                    v-if="selectReportId=='secondQuality'"
                    :items="form.order_expense_detail_second_quality"
                    @closeSecondReport="selectReportId=null">
                  </second-quality-table>
                </template> 
                </order-reports>

                <order-forms
                :body-sizes="defaultBodySizes"
                :order="form"
                :csr="csr"
                :form-stop-options="options.formStopOptions"
                >
                  
                </order-forms>
                <order-files
                :order-id="form.id"
                >
                  
                </order-files>
                
              <!-- <div class="col-12 text-center">
                <button
                    class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer mr-2"
                    @click="onDownloadButtonClick">
                  {{ $t('order.show_report') }}
                </button>

                <button
                    class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer ml-2"
                    @click="downloadPdf">
                  {{ $t('order.download_report') }}
                </button>
              </div> -->
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <CoolLightBox
        :index="imageIndex"
        :items="imageLinks"
        @close="imageIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations, mapState} from 'vuex'
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  GET_PDF,
  LOADING,
  SET_LOADING,
  UPDATE_ITEM_BY_ID
} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import {
  EXPENSE_DETAIL,
  FORM,
  RESET_FORM,
  SET_EXPENSE_DETAIL,
  SET_FORM,
  SET_ORDER_SIZE_EXPORT,
  GET_ORDER_SIZE_EXPORT,
  DELETE_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE,
  DELETE_ORDER_SIZE_AND_COLOR_EXPORT_DATE,
  SET_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE,
  SET_ORDER_SIZE_AND_COLOR_EXPORT_DATE,
} from "@/core/services/store/order/edit-order.module"
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import TextInput from "@/assets/components/inputs/TextInput";
import _ from 'lodash'
import csrData from "@/view/pages/csr/csr-data.js";

import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT,KT_DATE_FORMAT} from "@/core/config/constant";
import QRCode from "@/assets/components/widget/QRCode";
import Tooltip from "@/view/pages/vue-bootstrap/Tooltip";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import OrderFabric from "@/view/pages/order/layout/OrderFabric";
import OrderReports from "@/view/pages/order/layout/OrderReports";
import OrderForms from "@/view/pages/order/layout/OrderForms";
import OrderFiles from "@/view/pages/order/layout/OrderFiles";
import SecondQualityTable from "@/view/pages/order/layout/SecondQualityTable";
import OrderPdfReport from "@/view/pages/order/pdf/OrderPdfReport";
import InstructionsModal from "@/view/pages/shared/instructionsModal";
import moduleUser, {GENERAL_SETTING, MODULE_NAME as MODULE_USER} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import $ from "jquery";
import pdf from "vue-pdf";
import print from 'vue-print-nb';
import Vue from "vue";
import { integer } from "vuelidate/lib/validators";
import CutReport from './report/CutReport.vue';
const _MODULE_USER = MODULE_USER;

export default {
  name: "CreateNewOrder",
  directives: {
      print   
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  components: {
    InstructionsModal,
    OrderFabric,
    InfoTooltip,
    Tooltip,
    QRCode,
    NumberInputWithUnit,
    DatePickerInput,
    ButtonWithIcon,
    TextInput,
    CustomMultiSelect,
    NumberInput,
    DashboardBox,
    OrderReports,
    OrderForms,
    OrderFiles,
    pdf,
    OrderPdfReport,
    CutReport,
    SecondQualityTable
  },
  computed: {
    moment: () => moment,
    ...mapGetters({
      loading: LOADING,
    }),
    ...mapGetters({
      getOrderSizeExport:GET_ORDER_SIZE_EXPORT,
    }),
    sortBodySizeDefault(){
      let sizes = this.defaultBodySizes;
      if(sizes){
        let formatedBodySize =this.sortBodySize(sizes);
        return formatedBodySize;
      }
      return null;
      

    },
    formatedOrderExport() {
    let orderExportDates = this.form.order_export_dates;
    let formatedExportDates = [];
    let formatedDate = null;
    orderExportDates.forEach((exportDate, index) => {
        let date = moment(exportDate.export_date); // Tarihi klonla
        formatedExportDates.push({name: date.format("DD.MM.YYYY"), id: date.format("YYYY-MM-DD"),order_export_date_id:exportDate.id});
        formatedDate = null;
        date = null;
    });
    let items = Object.assign({},formatedExportDates);
    return items;
   },
    isVisibleSalePrice(){
      if(!this.form.id){
        return true;
      }
      if(this.isUserGranted('Order', ['canSeeSalePrice'],false,true)){
        return true;
      }
      return false;
    },
    isDisableUpdateExtraAmount(){
      return this.isUserGranted('Order', ['updateExtraAmount'], false, true) ? false : true;
    },
    isDisableCalculateCosts(){
      return this.isUserGranted('Order', ['calculateCosts'], false, true)? false : true;
    },
    isDisablePriceEdit() {
      return this.isUserGranted('Order', ['updatePrice'], false, true) ? false : true;
    },
    defaultAmount() {
      return function (data, value, key) {
        if (key === 2) {
          if(this.defaultTotals){

            let item = _.find(this.defaultTotals,{'id':data.id})
            if(item){
              let findBodySize = _.find(item.body_sizes,{'body_size_id':value.body_size_id});
              return item ? findBodySize.amount : 0;
            }
            return null;
          }
          return null;
        }
      }
    },
    sizeAndColorOptions() {
      let self = this;
      let items = _.cloneDeep(this.form.order_size_and_colors)

      if (items) {
        return self.convertArrayToObjectByKey(items, 'id', 'color_name');
      }

      return {};
    },
    vatRateOptions() {
      let options = this.options.VATRateOptionsRaw || {};
      let data = options;

      let activeTab = this.activeTab;
      if (+activeTab === 1) {
        let expenseTypeId = this.expense_data.typeId;
        if (options && expenseTypeId) {
          data = data.filter(item => +item.type_id === expenseTypeId)
        }
      } else if (+activeTab === 2) {
        data = options.filter(item => +item.type_id === 4)

      }
      return this.convertArrayToObjectByKey(data, 'id', 'vat.rate');
    },
    ...mapState({
      form: state => state.editOrder[FORM],
      expense_details: state => state.editOrder[EXPENSE_DETAIL],
    }),
    totalSizeAndColor() {
      return function (item) {
        let items = _.values(item.body_sizes);
        let amount = 0;
        items.forEach(subItem => {
          amount += Number(subItem.amount);
        })

        return amount;
      }
    },
    expenseCoefficient() {
      let fabricRequests = 10;

      if (this.settings) {
        let settings = _.first(this.settings);
        if (settings) {
          fabricRequests = settings.fabric_request.expense_coefficient;
        }
      }
      return fabricRequests;
    },
    settings() {
      return this.$store.getters[_MODULE_USER + '/' + GENERAL_SETTING]
    },
    yesNoOptions() {
      let data = csrData.yesNo;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    disneyLicences() {
      let data = csrData.disneyLicences;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    joinLifeOptions() {
      let data = csrData.joinLife;
      return this.convertArrayToObjectByKey(data, 'id', 'name')
    },
    finalTotal() {
      let total = 0;
      this.form.order_fabrics.forEach(fabric => {
        total += Number(fabric.pastal)
      });

      return total * Number(this.form.amount);
    },

    currentOrderExpenseDetail: {
      get() {
        if (this.expense_data.selectedExpenseID === null) return null;
        if (this.expense_data.selectedExpenseID in this.expense_details) {
          return this.expense_details[this.expense_data.selectedExpenseID];
        }
        return null;
      },
      set(value) {
        if (this.expense_data.selectedExpenseID === null) return null;
        if (!(this.expense_data.selectedExpenseID in this.expense_details)) {
          return null;
        }
        this.$set(this.expense_details, this.expense_data.selectedExpenseID, {...value});
      }
    },
    fabricTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.order_fabrics.length; i++) {
        total += (Number(this.form.order_fabrics[i].pastal) * Number(this.form.order_fabrics[i].unit_price));
      }
      let rate = 1;
      /*      if (this.form.currency_unit_id in this.options.currencyOptions) {
              rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[this.form.currency_unit_id]].rate);
            }*/
      return (isNaN(total * rate) ? 0 : total * rate).toFixed(2);
    },
    expenseTotalAmount() {
      let total = 0
      for (let i = 0; i < this.form.order_expenses.length; i++) {
        total += Number(this.form.order_expenses[i].amount) * Number(this.form.order_expenses[i].planned)
      }
      let rate = 1;
      /*      if (this.form.currency_unit_id in this.options.currencyOptions) {
              rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[this.form.currency_unit_id]].rate);
            }*/
      return (isNaN(total * rate) ? 0 : total * rate).toFixed(2);
    },
    sizeAndColorTotal() {
      let total = 0;
      this.form.order_size_and_colors.forEach((value, index) => {
        for (let key in value.body_sizes) {
          total += Number(value.body_sizes[key].amount);
        }
      })
      return total;
    },
    sizeAndColorTotalByIndex() {
      let size_and_colors = this.form.order_size_and_colors;
      return function (index) {
        let current = size_and_colors[index], total = 0;
        for (let key in current.body_sizes) {
          total += Number(current.body_sizes[key].amount);
        }
        return total;
      }
    },
    isFabricCompositionValid() {
      if (this.form.fabric_composition == '' || this.form.fabric_composition == undefined ) return false;
      return true;
    },
    isOrderExportDateAmountValid() {
      if (Number(this.form.amount) === 0) return false;
      let total = 0;
      for (let i = 0; i < this.form.order_export_dates.length; i++) {
        total += Number(this.form.order_export_dates[i].amount);
      }
      if (total !== Number(this.form.amount)) return false;
      return true;
    },
    isValidToContinueOnGeneral() {
      if (this.form.esc_table_id === null) return false;
      if (this.form.model === null || this.form.model === '') return false;
      if (Number(this.form.company_id) === 0) return false;
      if (Number(this.form.status_id) === 0) return false;
      if (this.form.po_number === null || this.form.po_number === '') return false;
      if (this.form.image_link === null && this.form.image) return false;
      if (Number(this.form.amount) === 0) return false;

      return true;
    },
    isValidToContinueOnFabric() {
      if (!this.isValidToContinueOnGeneral) return false;
      if (Number(this.form.sale_price) === 0) return false;
      if (Number(this.form.currency_unit_id) === 0) return false
      if (this.form.qc_user_id === null) return false;
      let total = 0;
      for (let i = 0; i < this.form.order_export_dates.length; i++) {
        if (this.form.order_export_dates[i].export_date === null) return false;
        if (Number(this.form.order_export_dates[i].amount) === 0) return false;
        total += Number(this.form.order_export_dates[i].amount);
      }
      if (total !== Number(this.form.amount)) return false;
      if (this.form.description === null || this.form.description === '') return false;
      if (this.form.fabric_composition==undefined || this.form.fabric_composition === null || this.form.fabric_composition === '') return false;
      if (this.form.amount_per_parcel === null) return false;
      
      
      return true;
    },
    isValidToContinueOnExpenses() {
      if (!this.isValidToContinueOnFabric) return false;
      for (let i = 0; i < this.form.order_fabrics.length; i++) {
        let current = this.form.order_fabrics[i];
        if (current.type === null || current.type === '') return false;
        if (!current.weight) return false;
        if (!current.width) return false;
        if (Number(current.pastal) === 0) return false;
        if (Number(current.unit_id) === 0) return false;
        if (Number(current.unit_price) === 0) return false;
        let total = 0;
        if (current.order_fabric_compositions.length === 0) return false;
        for (let j = 0; j < current.order_fabric_compositions.length; j++) {
          let temp = current.order_fabric_compositions[j];
          if (Number(temp.fabric_composition_id) === 0) return false;
          //if (Number(temp.amount) === 0) return false; // 6 Nisan Tarihinde Neslihan hanımın Pamuğu çıkarıp yerine başka bir şey ekleyememesinden dolayı 0 ibaresi kaldırdı çıkarmak istedikleri yer 0 yazacaklar
          total += Number(temp.amount);
        }
        if (total !== 100) return false;
      }
      return true;
    },
    isValidToContinueOnSizeAndColor() {
      if (!this.isValidToContinueOnExpenses) return false;
      for (let i = 0; i < this.form.order_expenses.length; i++) {
        let current = this.form.order_expenses[i];
        if (Number(current.planned) === 0) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.unit_id) === 0) return false;
        if (Number(current.currency_unit_id) === 0) return false;
      }
      return true;
    },
    isValidToSubmit() {
      if (!this.isValidToContinueOnSizeAndColor) return false;
      let total = 0;

      for (let i = 0; i < this.form.order_size_and_colors.length; i++) {
        const size = this.form.order_size_and_colors[i], tempTotal = Number(this.sizeAndColorTotalByIndex(i));
        if (tempTotal === 0) return false;
        total += tempTotal;
        if (size.color_number === null || size.color_number === '') return false;
        if (size.color_name === null || size.color_name === '') return false;
        if (size.is_submit_order_export == false)  return false;
        if (size.export_dates.length<=0) return false;
     
      }
      if (Number(total) !== Number(this.form.amount)) return false;
      return true;
    }
  },
  data() {
    return {
      tmpOrderSizeAndColorId:null,
      pdfs: [],
      orderReportData:[],
      selectReportId:0,
      defaultBodySizes:null,
      src: null,
      numPages: undefined,
      currentPDF: {
        blob: null,
      },

      imageLinks: [],
      imageIndex: null,

      csr: {
        disney_licences: {
          disney_id: [],
          license_id: [],
        },
        join_life: [],
      },
      reportPrintObject:{
        id: "reportPrint",
        popTitle: localStorage.getItem('VUE_APP_NAME'),
        
      },
      instructionTypes: null,
      instructions: null,
      expenses: [],
      orderAllResult: [],
      is_submitting: false,
      defaultTotals: [],
      expense_data: {
        orderExpenseId: null,
        selectedExpenseID: null,
        typeId: null,
        instructionTypeId: null,
      },

      today: moment(),
      orderDate: moment(),

      // Control Variables
      currentActiveTab: 4,
      defaultSizeJSON: {},

      id: this.$route.params.id,
      type: Number(this.$route.params.type),
      esc_id: Number(this.$route.query.esc_id),
      sample_id: Number(this.$route.query.sample_id),
      activeTab: 1,

      instructionTypeId: null,
      csrSupplierCompanies: {},
      options: {
        defaultBodySizes:[],
        customerOptions: [],
        orderStatusOptions: [],
        currencyOptions: {},
        currencyRawOptions: [],
        qcUserOptions: [],
        fabricCompositionOptions: [],
        expensesOptions: [],
        optionalExpenseOptions: [],
        unitOptions: [],
        unitOptions2: [],
        unitOptions3: [],
        bodySizeOptions: [],
        supplierCompanyOptions: [],
        dispatchLocationOptions: [],
        snapTypeOptions: [],
        VATRateOptions: [],
        VATRateOptionsRaw: [],
        currencyRateOptions: [],
        fabricYarnTypeOptions: [],
        testMethodOptions: [],
        fabricKnitTypeOptions: [],
        fabricTypeOptions: [],
        fabricProcessTypeOptions: [],
        fabricAdditionOptions: [],
        fabricPrintTypeOptions: [],
        productProcesses: [],
        formStopOptions:[],
      },
      expenseIncomingData: [],
      tempFormOrderExpensesForQrScanner:[]
    };
  },
  methods: {
    ...mapMutations({
      setForm: SET_FORM,
      setExpenseDetail: SET_EXPENSE_DETAIL,
      resetForm: RESET_FORM,
      setOrderSizeExport:SET_ORDER_SIZE_EXPORT,
      deleteOrderSizeAndColorSelectedExportDate:DELETE_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE,
      deleteOrderSizeAndColorExportDate:DELETE_ORDER_SIZE_AND_COLOR_EXPORT_DATE,
      setOrderSizeAndColorselectedExportDate:SET_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE,
      setOrderSizeAndColorExportDate:SET_ORDER_SIZE_AND_COLOR_EXPORT_DATE,
    }),
    deleteExportDateBodySize(indexDate){
      let selectedDates = _.cloneDeep(this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].export_dates[indexDate])
       const dateToFind = selectedDates.date;
       const findSelectedIndex = _.findKey(this.formatedOrderExport, { 'id': dateToFind });
       let self = this;
      if(this.form.id&&selectedDates.id){
        this.sweetAlertConfirm(this.$t('general.are_you_sure')).then(res => {
        if (res) {
          self.$store.dispatch(DELETE_ITEM_BY_ID, {
            url: `api/orders/delete-export-date/${selectedDates.id}`
          }).then(result => {
            if (result.status) {
              this.deleteOrderSizeAndColorSelectedExportDate({
                  tmpOrderSizeAndColorId:this.tmpOrderSizeAndColorId,
                  findSelectedIndex:findSelectedIndex
              });
              this.deleteOrderSizeAndColorExportDate({
                  tmpOrderSizeAndColorId:this.tmpOrderSizeAndColorId,
                  indexDate:indexDate
              });
            }
          })
        } else {
          
        }
      })
      }else{
        this.deleteOrderSizeAndColorSelectedExportDate({
            tmpOrderSizeAndColorId:this.tmpOrderSizeAndColorId,
            findSelectedIndex:findSelectedIndex
        });
        this.deleteOrderSizeAndColorExportDate({
            tmpOrderSizeAndColorId:this.tmpOrderSizeAndColorId,
            indexDate:indexDate
        });
      }
      
    },
    onCurrencyFabricChange(index){
      this.form.order_fabrics[index].exchange_rate=null;
    },
    selectReport(selectReport){
      this.currentPDF = this.form 
      if(selectReport=="planReport"){
        this.selectReportId=selectReport;
        let self = this;
        self.$store.commit(SET_LOADING, true);
        this.$store.dispatch(GET_ITEMS, {
          url: "api/orders/order-pdf-data",
          filters:{
            order:this.form.id
          }
        }).then(result => {
          if(result.status) {
            this.orderReportData=result.data;
            self.$store.commit(SET_LOADING, false);
            self.$refs['display-order-pdf'].show()
          }
        });
      }else if(selectReport=="cutReport"){
        let self = this;
        self.$store.commit(SET_LOADING, true);
        this.$store.dispatch(GET_ITEMS, {
          url: "api/orders/order-cut-report",
          filters:{
            order:this.form.id
          }
        }).then(result => {
          if(result.status) {
            this.selectReportId=selectReport;

            this.orderReportData=result.data;
          }
         self.$store.commit(SET_LOADING, false);

        });
      } else if(selectReport=="secondQuality"){
        this.selectReportId=selectReport;
      }
    },
    openExportDatePopup(fabricIndex){
      let permission =true ;
      let fabricAndColorsIndex = _.cloneDeep(this.form.order_size_and_colors[fabricIndex]);
      if(fabricAndColorsIndex.color_name==null || fabricAndColorsIndex.color_name=="" ){
        this.sweetAlertError(this.$t('order.please_entry_color_name_or_number'));
        permission = false;
      }
      if(fabricAndColorsIndex.color_number==null || fabricAndColorsIndex.color_number=="" ){
        this.sweetAlertError(this.$t('order.please_entry_color_name_or_number'));
        permission = false;
      }

      let self = this;
      Object.keys(fabricAndColorsIndex.body_sizes).forEach(function(key) {
        if(fabricAndColorsIndex.body_sizes[key].amount == null || fabricAndColorsIndex.body_sizes[key].amount==""){
          self.sweetAlertError(self.$t('order.please_entry_size_amount'));
          permission = false;
        }
      });

      if(permission){
        this.tmpOrderSizeAndColorId = fabricIndex;
        this.$modal.show('open-export-date-modal')
      }
    },
    downloadPdf() {
      this.currentPDF = this.form
      this.openInNewTab(this.currentPDF.pdf_link)
    },
    onDownloadButtonClick() {
      try{
        this.currentPDF = this.form
        let self = this;
        self.$store.commit(SET_LOADING, true);
        this.$store.dispatch(GET_PDF, {
          url: self.currentPDF.pdf_link,
        }).then(result => {
          if(result.status) {
            self.src = URL.createObjectURL(result.data)
            self.$store.commit(SET_LOADING, false);
            self.$refs['display-order-pdf'].show()
          }
        });
      }catch(e){
        console.log(e);
      }
      
    },
    isValidChangeGeneralTab(){
      if (!this.csr.join_life.length) { 
        this.sweetAlertError(this.$t('csr.validations.join_life'));
        return false;
      }
     
      
      return true;
    },
    isValidFabricComposition(){
      if (this.form.fabric_composition == undefined || this.form.fabric_composition == '') { 
        this.currentActiveTab = 1
        this.sweetAlertError(this.$t('Kumaş Komposizyonu zorunludur.'));
        return false;
      }  
      return true;
    },
    calculateCost(){
      if(!this.isUserGranted('Order', ['calculateCosts'], false,true)){
        return false;
      }
      let self = this
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/orders/calculate-costs/${self.id}`,
        contents:{
          id:self.id,
          waybillable_type:'App\\Models\\Order'
        },
        showLoading:true,
      }).then((result) => {
        if (result.status) {
          this.setup();
        }else{
          let data=result.data.response;
          self.sweetAlertError(data.data.message);
        }
      })
    },
    getTotalValue(value, key) {
      let currentValue = this.cloneForce(value)
      return currentValue.amount;
    },
    getOriginalImage() {
      this.imageLinks = [this.form.image_link_large];
      this.imageIndex = 0;
    },
    onChangeSizeAndColor($event, data) {
      data.body_sizes = null;
      data.amount = 0;
      let tmpOrderSizeAndColors = [];
      let orderSizeAndColors = _.cloneDeep(this.form.order_size_and_colors);

      this.form.order_size_and_colors.forEach((color,colorIndex)=>{
        let tmpBodySize = {};
        Object.entries(color.body_sizes).forEach((size) => {
          size[1].amount = Number(size[1].amount);
          tmpBodySize[size[0]] = _.cloneDeep(size[1]);
        });
        let tmpColor = _.cloneDeep(color.body_sizes);
        tmpOrderSizeAndColors.push(tmpColor);
        orderSizeAndColors[colorIndex].body_sizes=[];
        orderSizeAndColors[colorIndex].body_sizes=tmpBodySize;
      });



      let orderSizeAndColorId = Number($event);
      let orderSizeAndColor = _.find(orderSizeAndColors,{'id':+orderSizeAndColorId});

      let entranceOrderSizeAndColors = this.expenseIncomingData;
      let incomingAmount = 0;
      entranceOrderSizeAndColors.forEach(sizeColor=>{
        if(sizeColor.order_size_and_color_id == orderSizeAndColorId){
          incomingAmount += sizeColor.amount;
        }
      });
      if (+data.expense_type_id !== 2) {
        if(entranceOrderSizeAndColors.length > 0) {
          entranceOrderSizeAndColors.forEach(sizeColor=>{
            if(sizeColor.order_size_and_color_id == orderSizeAndColorId){
              let incomingBodySizes = sizeColor ? JSON.parse(sizeColor.body_sizes) : [];

              incomingBodySizes = _.map(incomingBodySizes, (value, key) => ({key, ...value}));
              incomingBodySizes.forEach(item => {
                let key = item.key;
                let tmpItem = _.cloneDeep(item);
                tmpItem.amount = Number(tmpItem.amount)
                orderSizeAndColor.body_sizes[key].amount= orderSizeAndColor.body_sizes[key].amount - Number(item.amount);
              })
              data.body_sizes=[];
              data.body_sizes = _.cloneDeep(orderSizeAndColor.body_sizes);
            }else{
              data.body_sizes=[];
              data.body_sizes = _.cloneDeep(orderSizeAndColor.body_sizes);
            }
          })
        }else{
          data.body_sizes=[];
          data.body_sizes = _.cloneDeep(orderSizeAndColor.body_sizes);
        }
        
        

      } else {
        data.total = orderSizeAndColor.total - incomingAmount;
      }
      this.defaultTotals = [];
      let tempData = _.cloneDeep(data);
      let newAmount = tempData.total + 0.05 * tempData.total;
      let bodySizes = tempData.body_sizes;

      // bodySizes.map(item => {
      //   item.amount = Number(item.amount) + 0.05 * Number(item.amount);
      //   return item;
      // });
      this.defaultTotals.push({
        id: tempData.id,
        total: newAmount,
        body_sizes: bodySizes,
        order_size_and_color_id: orderSizeAndColorId,
      })
    },
    changeSelectedExpense(expense) {
      this.instructionTypeId = Number(expense.expense.instructionable[0].id);
      this.expense_data.instructionTypeId = Number(expense.expense.instructionable[0].id);
      this.expense_data.selectedExpenseID = Number(expense.expense_id);
      this.expense_data.typeId = Number(expense.expense.type_id);
      this.expense_data.orderExpenseId = Number(expense.id);
      this.options.supplierCompanyOptions=[];
      this.csrSupplierCompanies=[];
      this.getSupplierCompanies();
      this.getIncomingExpense();
    },
    getInstructionTypes() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/instruction-type',
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.instructionTypes = result.data;
        }
      })
    },
    closeModal(name = "open_to_settings_table") {
      this.$refs[name].hide()
    },
    onCloseModal() {
      this.instructions = null;
      this.$refs['instructions-modal'].hide()
    },
    showInstructions() {
      let self = this;
      let filters = {
        instruction_type: 'O-' + self.id
      }
      self.$store.commit(SET_LOADING, true);

      self.$store.dispatch(GET_ITEMS, {
        url: `api/instructions`,
        filters: filters
      }).then((result) => {
        self.$store.commit(SET_LOADING, false);
        if (result.status) {
          self.instructions = result.data;
          self.$refs['instructions-modal'].show()
        } else {
          self.instructions = null;
        }
      })

      self.$store.dispatch(GET_ITEMS, {
        url: `api/orders/${this.id}/order-expenses`,
        filters: filters
      }).then((result) => {
        self.$store.commit(SET_LOADING, false);
        if (result.status) {
          self.expenses = result.data;
        } else {
          self.expenses = null;
        }
      })
    },
    showWaybills(itemId, type = 1, name = "") {
      let self = this;
      if (+type === 1) {
        this.$router.push({
          name: 'waybill.detail.index',
          params: {id: 'O-' + self.id, type: type, detailId: itemId, name: name}
        });
      }
      if (+type === 2) {
        this.$router.push({
          name: 'waybill.detail.index',
          params: {id: 'O-' + self.id, type: type, detailId: itemId, name: name}
        });
      }
      if (+type === 3) {
        let product_id = itemId.order_fabric.id;
        let order_size_and_color_id = itemId.order_size_and_color.id;
        this.$router.push({
          name: 'waybill.detail.index',
          params: {
            id: 'O-' + self.id,
            type: type,
            detailId: product_id,
            sizeAndColorId: order_size_and_color_id,
            name: name
          }
        });
      }
    },
    sendExpenseDetail(expenseDetail, expenseDetailIndex) {
      let self = this;
      let payloads = {
        url: 'api/order-expense-details/' + expenseDetail.id + '/share',
      }

      this.sweetAlertLoading();
      this.$store.dispatch(CREATE_ITEM, payloads).then(response => {
        self.sweetAlertClose();
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.success'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
      }).catch(response => {
        self.sweetAlertClose();
        let result = response.data.response;
        if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
          self.sweetAlertError(result.data.message);
        } else {
          self.sweetAlertError(result.data);
        }
      });
    },
    cancelExpenseDetail(expenseDetail, expenseDetailIndex) {
      if (this.is_submitting) return;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertConfirm(this.$t('general.are_you_sure')).then(res => {
        if (res) {
          self.$store.dispatch(DELETE_ITEM_BY_ID, {
            url: `api/orders/${self.form.id}/expenses/1/details/${expenseDetail.id}`
          }).then(result => {
            if (result.status) {
              self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
              if (this.currentOrderExpenseDetail.order_expense_details.length === 1) {
                this.addNewExpenseDetail()
              }
              this.currentOrderExpenseDetail.order_expense_details.splice(expenseDetailIndex, 1);
            } else {
              this.sweetAlertError(this.$t('order.cannot_delete_expense_has_waybill'));
            }

            self.is_submitting = false;
          })
        } else {
          self.is_submitting = false;
        }
      })
    },
    addNewTarget(index) {
      const i = this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets.length;
      let targetItem = this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets[i - 1];

      targetItem = moment(targetItem.date).add(1, 'day');

      const dayNumber = targetItem.isoWeekday();
      if (dayNumber === 7) {
        targetItem.add(1, 'days');
      }
      this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets.push({
        date: targetItem.format('YYYY-MM-DD'),
        target: null,
      });
    },
    isVisibleDeleteTarget(payload){
      let today=moment().subtract(1,'d').format('YYYY-MM-DD');
      let date=moment(payload).format('YYYY-MM-DD')
      if(today>=date){
        return false;
      }
      return true;
    },
    deleteNewTarget(index, target_index) {
      if(this.instructionTypeId==28 || this.instructionTypeId==29 || this.instructionTypeId==30){
          if( this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets[target_index].id&&this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets[target_index].actual>0){
          this.sweetAlertError(this.$t('order.you_cannot_delete_it_because_the_production'));
          return false;
        }
      }
      
      this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets.splice(target_index, 1);
    },
    changeToEditMode(expenseDetail, expendDetailIndex) {
      expenseDetail.is_editing = true;
    },
    fixedAndUpdateOrderExpenseDetails(data) {
      let amount = 0;
      if (Number(data.expense_type_id) !== 2) {
        let items = _.values(data.body_sizes);
        items.forEach(subItem => {
          amount = Number(amount) + Number(subItem.amount);
        })
      } else {
        amount = Number(amount) + Number(data.total);
      }
      data.total = Number(amount);
      data.amount = Number(amount);
      data.total_amount = Number(amount);

      data.start_date = moment(data.start_date).format(LARAVEL_DATE_FORMAT);
      data.end_date = moment(data.end_date).format(LARAVEL_DATE_FORMAT);
      data.end_date = moment(data.end_date).format(LARAVEL_DATE_FORMAT);
      data.body_sizes = JSON.stringify(_.cloneDeepWith(data.body_sizes));
      data.order_expense_detail_targets.forEach(subItem => {
        subItem.date = moment(subItem.date).format(LARAVEL_DATE_FORMAT);
      });

      return data;
    },
    isValidToCreateInstruction(expenseDetailIndex) {
      if (!this.currentOrderExpenseDetail) return false;
      let current = this.currentOrderExpenseDetail.order_expense_details[expenseDetailIndex];
      let currentExpense = this.form.order_expenses.find(item => +item.id === +current.order_expense_id);
      let ignoreCheckPrice=currentExpense.expense.ignore_check_price;
      if (!current || !currentExpense) return false;
      let planned = parseFloat(currentExpense.planned);

      if (Number(current.supplier_company_id) === 0) return 'select_supplier_company';

      if (Number(current.dispatch_location_id) === 0) return 'select_dispatch_location';
      if (Number(current.currency_unit_id) === 0) return 'select_currency_unit';

      if (current.expiry_id === null) return 'select_expiry';
      if (Number(this.expense_data.selectedExpenseID) === 17 && Number(current.snap_type_id) === 0) return 'select_snap_type';

      if (Number(current.expense_type_id) !== 2) {
        if (current.body_sizes === {}) return 'select_body_size';
      }
      if (!current.order_size_and_color_id) return 'select_size_and_color';
      if (Number(current.unit_id) === 0) return 'select_unit';
      if (Number(current.price) === 0) return 'enter_price';
      if (ignoreCheckPrice!=1 && Number(current.price) > planned) return 'price_not_gt_planned';
      if (Number(current.vat_rate_id) === 0) return 'select_vat_rate';
      let totalAmount = 0;

      this.currentOrderExpenseDetail.order_expense_details.forEach(item => {
        let amount = 0;
        if (Number(item.expense_type_id) !== 2) {
          let items = _.values(item.body_sizes);
          items.forEach(subItem => {
            amount += Number(subItem.amount);
          })
          totalAmount += amount;
        } else {
          totalAmount = item.total;
        }
      })

      let tmpExpenseCoefficient=this.expenseCoefficient;
      let orderExpenseItem = this.form.order_expenses.find((orderExpenseItem) => +orderExpenseItem.id === +current.order_expense_id).expense;

      // Dikim ütü pakete göre fire oranı %5 yapılıyor
      if(orderExpenseItem.logo_id==1909){
        tmpExpenseCoefficient=5;
      }

      const orderExpenseAmount = +this.form.order_expenses.find((orderExpenseItem) => +orderExpenseItem.id === +current.order_expense_id).amount;
      const orderAmount = this.form.amount * orderExpenseAmount;
      const amountWithCoefficient = orderAmount + orderAmount * tmpExpenseCoefficient / 100;
      if (+totalAmount > amountWithCoefficient) return 'amount_not_gt_order';
      let productAmount = 0;

      let amount = 0;
      if (Number(current.expense_type_id) !== 2) {
        let items = _.values(current.body_sizes);
        items.forEach(subItem => {
          amount += Number(subItem.amount);
        })
        productAmount += amount;
      } else {
        productAmount = current.total;
      }

      totalAmount = 0;
      current.order_expense_detail_targets.forEach(target => {
        totalAmount += parseFloat(target.target);
      });

      if (+totalAmount !== +productAmount) return 'amount_not_gt_product';

      return true;
    },

    createOrUpdateInstruction(expendDetailIndex) {
      let checkValidations = this.isValidToCreateInstruction(expendDetailIndex);
      if (checkValidations !== true) {
        this.sweetAlertError(this.$t('general.' + checkValidations));
        return false;
      }
      let contents = _.cloneDeepWith(this.currentOrderExpenseDetail), self = this;
      this.sweetAlertLoading();

      let formData = this.fixedAndUpdateOrderExpenseDetails(contents.order_expense_details[expendDetailIndex]);

      this.$store.dispatch(CREATE_ITEM, {
        url: `api/orders/${this.form.id}/order-expense-details`,
        contents: {order_expense_detail: formData},
      }).then(response => {
        self.sweetAlertClose();
        if (response.status) {
          let item = response.data;
          self.currentOrderExpenseDetail.order_expense_details[expendDetailIndex].id = item.id;
          self.currentOrderExpenseDetail.order_expense_details[expendDetailIndex].order_pdf_link = item.order_pdf_link;
          self.currentOrderExpenseDetail.order_expense_details[expendDetailIndex].order_excel_link = item.order_excel_link;
          self.currentOrderExpenseDetail.order_expense_details[expendDetailIndex].is_submitted = true;
          self.currentOrderExpenseDetail.order_expense_details[expendDetailIndex].is_editing = false;

          self.sweetAlertSuccess(self.$t('general.successfully_saved'))
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
      });
    },
    getIncomingExpense() {
      let self = this;
      let expenseId = this.expense_data.orderExpenseId

      let url = 'api/order/' + this.id + '/order-expense/' + expenseId + '/amount';
      this.$store.dispatch(GET_ITEMS, {
        url: url,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.expenseIncomingData = result.data;

          /*
                    if (self.netIncomingFabricAmount == fabricItem.amount) {
                      self.sweetAlertClose();
                      setTimeout(() => {
                        self.sweetAlertError("general.fabric_requests_completed");
                      }, 100)
                    }*/
        }
      })
    },
    addNewExpenseDetail() {
      let data = _.cloneDeepWith(this.expense_details[this.expense_data.selectedExpenseID].order_expense_details.slice(-1)[0])
      this.getIncomingExpense();
      let expenseDetailTemplate =
          {
            id: null,
            is_submitted: false,
            is_editing: true,
            order_size_and_color_id: null,
            order_expense_item_type_id: null,
            supplier_company_id: null,
            dispatch_location_id: null,
            currency_unit_id: '53',
            exchange_rate: '1',
            to_who: "",
            price: data.price,
            body_sizes: {},
            expiry_id: null,
            start_date: moment(),
            end_date: moment(),
            description: null,
            amount_per_parcel: 1,
            instruction_description: null,
            order_pdf_link: null,
            order_excel_link: null,
            snap_type_id: null,
            order_expense_detail_targets: [
              {
                date: moment(),
                target: null,
              }
            ]
          }

      let tmpData = {...data, ...expenseDetailTemplate};
      this.expense_details[this.expense_data.selectedExpenseID].order_expense_details.push(tmpData)
    },
    deleteNewExpenseDetail(index) {
      this.expense_details[this.expense_data.selectedExpenseID].order_expense_details.splice(-1)
    },
    onExpenseDetailCurrencyChange(payload) {
      let index = payload.name.split('-')[1], newValue = payload.newValue, oldValue = payload.oldValue;
      let rate = 1;
      this.expense_details[this.expense_data.selectedExpenseID].order_expense_details[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.options.currencyOptions) {
        rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[newValue[0]]].rate);
      }
      for (let i = 0; i < this.currentOrderExpenseDetail.order_expense_details.length; i++) {
        this.currentOrderExpenseDetail.order_expense_details[i].currency_unit_id = newValue[0];
      }
      this.expense_details[this.expense_data.selectedExpenseID].order_expense_details[index].exchange_rate = rate;
    },
    sortBodySize(bodySize){
      let tmpBodySize = bodySize;
      if(typeof tmpBodySize=='string'){
        tmpBodySize=JSON.parse(tmpBodySize);

      }
      tmpBodySize=Object.values(tmpBodySize);
      let sortBodySize=this.sortArrayOfGradings(tmpBodySize)
      return sortBodySize;
    },
    convertReturnOrderValueToValidFormData(data) {
      let self = this;
      
      if (Number(data.sample_id) !== 0 && !isNaN(Number(data.sample_id)) && false) {
        this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: `api/samples/${data.sample_id}`,
          id: data.sample_id,
        }).then(result => {
          if (result.status) {
            self.form.sample = result.data;
          }
        })
      }
      if (Number(data.esc_table_id) !== 0) {
        this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: `api/esc-tables/${data.esc_table_id}?single=1`,
          id: data.esc_table_id,
        }).then(result => {
          if (result.status) {
            self.form.esc = result.data;
            self.form.esc_table_id = result.data.id;
          }
        });
      }
      data.status_id = data.status_id.toString();
      this.orderDate = data.order_date;
      if ('order_export_dates' in data) {
        data.order_export_dates = data.order_export_dates.map((value, index) => {
          value.export_date = moment(value.export_date, LARAVEL_DATE_FORMAT);
          return value;
        })
      }
      this.setExpenseDetail({});
      let body_sizes = [];
      if ('order_size_and_colors' in data) {
        data.order_size_and_colors = data.order_size_and_colors.map((value, index) => {
          let selectedExportDate = []
          let exportDatesBodySizes = null;
          if('export_dates' in value){
            let items = value.export_dates;
            let newItems = items.forEach(exportDate=>{
              let date = exportDate.date;
              let self = this;
              data.order_export_dates.forEach((item,index)=>{
                let date =  moment(item.export_date).format(LARAVEL_DATE_FORMAT);
                value.export_dates.forEach((exportDate)=>{
               let exportDateDate = moment(item.export_date).format(LARAVEL_DATE_FORMAT);
                  if(exportDate.date == date){
                   let findIndex = _.findIndex(selectedExportDate, function(o) { return o==index });
                   if(findIndex==-1){
                     selectedExportDate.push(String(index))
                   }
                  }
                })
              })
              value.export_dates = value.export_dates.map(exportValue => {
                let body_sizes = exportValue.body_sizes;
                if(typeof body_sizes=='object'){
                  body_sizes = JSON.stringify(exportValue.body_sizes);
                }
                let jsonParseBodySize=JSON.parse(body_sizes);
                return {
                  body_sizes: this.sortBodySize(jsonParseBodySize),
                  id:exportValue.id,
                  order_export_date_id:exportDate.order_export_date_id,
                  color_name:exportValue.color_name,
                  color_number:exportValue.color_number,
                  order_id:exportValue.order_id,
                  order_size_and_color_id:exportValue.order_size_and_color_id,
                  date:exportValue.date
                };
              });

             
              
              
            })
          }
          try {
            value.body_sizes = JSON.parse(value.body_sizes);
            value.selected_dates=selectedExportDate;
            body_sizes.push({...value.body_sizes});
          } catch (e) {
            console.error(e);
            value.body_sizes = {};
            this.defaultBodySizes=null;
            body_sizes.push({});
          }

          return value;
        })
      }

      if ('order_fabrics' in data) {
        data.order_fabrics = data.order_fabrics.map((value, index) => {
          value.deadline_start = moment(value.deadline_start, LARAVEL_DATE_FORMAT);
          value.deadline_end = moment(value.deadline_end, LARAVEL_DATE_FORMAT);
          value.selected_order_fabric_compositions = [];
          if(value.currency_unit!=null){
            value.currency_unit_id=value.currency_unit.code;
          }
          if ('order_fabric_compositions' in value) {
            value.order_fabric_compositions = value.order_fabric_compositions.map((fabric_composition, i) => {
              value.selected_order_fabric_compositions.push(fabric_composition.fabric_composition_id);
              return fabric_composition;

            })
          }
          return value;
        })
      }

      if ('order_expenses' in data) {
        data.selected_expenses = [];
        data.order_expenses = data.order_expenses.map((value, index) => {
          data.selected_expenses.push(value.expense_id);
          if (value.expense_id in this.options.expensesOptions) {
            value.name = this.options.expensesOptions[value.expense_id].name;
            value.is_default = 0;
          } else if (value.expense_id in this.options.optionalExpenseOptions) {
            value.name = this.options.optionalExpenseOptions[value.expense_id].name;
            value.is_default = 1;
          }
          this.expense_details[value.expense_id] = {};
          if ('order_expense_details' in value && value.order_expense_details.length > 0) {
            this.expense_details[value.expense_id].order_expense_details = value.order_expense_details.map((order_expense_detail, index) => {
              order_expense_detail.start_date = moment(order_expense_detail.start_date, LARAVEL_DATE_FORMAT);
              order_expense_detail.end_date = moment(order_expense_detail.end_date, LARAVEL_DATE_FORMAT);
              order_expense_detail.select_dispatch_locations = [];
              order_expense_detail.expense_type_id = value.expense.type_id;
              order_expense_detail.is_submitted = true;
              order_expense_detail.is_editing = false;
              order_expense_detail.pdf_link = order_expense_detail.order_pdf_link;

              if ('order_expense_detail_dispatch_locations' in order_expense_detail && order_expense_detail.order_expense_detail_dispatch_locations.length > 0) {
                order_expense_detail.order_expense_detail_dispatch_locations.forEach((dispatch_location_detail) => {
                  order_expense_detail.select_dispatch_locations.push(dispatch_location_detail.dispatch_location_id);
                })
              }

              try {
                order_expense_detail.body_sizes = JSON.parse(order_expense_detail.body_sizes)
              } catch (e) {
                order_expense_detail.body_sizes = {};
              }

              if (!('order_expense_detail_targets' in order_expense_detail) && order_expense_detail.order_expense_detail_targets) {
                order_expense_detail.order_expense_detail_targets = {
                  date: moment(),
                  target: null,
                }
              } else {
                order_expense_detail.order_expense_detail_targets = order_expense_detail.order_expense_detail_targets.map(target => {
                  target.date = moment(target.date, LARAVEL_DATE_TIME_FORMAT);
                  return target;
                })
              }
              return order_expense_detail;
            });
          } else {
            let expense_body_size = {}, total = 0;
            body_sizes.forEach((body_size, i) => {
              for (let key in body_size) {
                if (key in expense_body_size) {
                  expense_body_size[key].amount = Number(expense_body_size[key].amount) + Number(body_size[key].amount);
                  expense_body_size[key].max_amount = Number(expense_body_size[key].amount) + Number(body_size[key].amount);
                } else {
                  expense_body_size[key] = {...body_size[key]};
                }
                total += Number(body_size[key].amount);
              }
            })

            for (let key in expense_body_size) {
              expense_body_size[key].amount = expense_body_size[key].amount;
              expense_body_size[key].max_amount = expense_body_size[key].amount;
            }

            let tmpExpenseTypeId = 1;
            if (value.expense_id && value.expense_id in this.options.expensesOptions) {
              tmpExpenseTypeId = this.options.expensesOptions[value.expense_id].type_id;
            }

            if (value.expense_id && value.expense_id in this.options.optionalExpenseOptions) {
              tmpExpenseTypeId = this.options.optionalExpenseOptions[value.expense_id].type_id;
            }

            this.expense_details[value.expense_id] = {

              order_expense_details: [
                {
                  id: null,
                  is_submitted: false,
                  is_editing: true,
                  order_expense_id: value.id,
                  expense_type_id: tmpExpenseTypeId,
                  order_size_and_color_id: null,
                  order_expense_item_type_id: null,
                  supplier_company_id: null,
                  dispatch_location_id: null,
                  currency_unit_id: '53',
                  exchange_rate: '1',
                  to_who: "",
                  expiry_id: null,
                  start_date: moment(),
                  end_date: moment(),
                  description: null, // nullable
                  order_pdf_link: null,
                  order_excel_link: null,
                  snap_type_id: null, // nullable if not cit cit
                  body_sizes: null,
                  total: 0,
                  unit_id: value.unit_id,
                  price: value.planned,
                  discount: null,
                  vat_rate_id: null,
                  order_expense_detail_targets: [
                    {
                      date: moment(),
                      target: null,
                    }
                  ]
                }
              ]
            }
          }
          // This line of code make expense_details reactive in vue
          this.setExpenseDetail({...this.expense_details});
          return value;
        })
      }

      if (data.hasOwnProperty('csr') && data.csr) {
        if (data.csr && !data.csr.disney_licences) {
          data.csr.disney_licences = {
            license_id: [],
            disney_id: [],
          }
        }

        if (!data.csr.join_life) {
          data.csr.join_life = [];
        }

        this.csr = data.csr;
      }

      data = {...this.form, ...data};
      this.setForm(data);

      self.imageLinks = [data.image_link];
      this.getSupplierCompanies();
      this.getDefaultBodySize(data.company_id)
      
    },
    removeSizeFromList(index, key) {
      let self = this;
      this.sweetAlertConfirm(this.$t('general.are_you_sure')).then(result => {
        if (result) {
          let temp = {...self.form.order_size_and_colors[index].body_sizes};
          delete temp[key];
          self.$set(self.form.order_size_and_colors[index], 'body_sizes', temp);
        }
      })
    },
    getSupplierCompanies() {
      let content = _.clone(this.csr);
      content.company_id = this.form.company_id ? this.form.company_id.toString(): null;

      if (this.instructionTypeId) {
        content.instructionTypeId = this.instructionTypeId;
      } else {
        delete content.instructionTypeId;
      }

      let self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: 'api/csr/supplier-companies',
        contents: content
      }).then(response => {
        if (response.status) {
          let items = [];
          response.data.forEach(item => {
            items.push({
              id: item.supplier_company.id,
              name: item.supplier_company.name,
            })
          })

          self.options.supplierCompanyOptions = self.convertArrayToObjectByKey(items, 'id', 'name');
          self.csrSupplierCompanies = response.data;
        }
      });
    },
    submitOrderCsr() { 
      if (!this.csr.join_life.length) {
        this.sweetAlertError(this.$t('csr.validations.join_life'));
        return false;
      }
      
      if (!this.csr.disney_licences.disney_id.length) {
        this.sweetAlertError(this.$t('csr.validations.disney_id'));
        return false;
      }

      if (!this.csr.disney_licences.license_id.length) {
        this.sweetAlertError(this.$t('csr.validations.license_id'));
        return false;
      } 
      if (this.id) {
        let contents = {
          csr: this.csr
        };

        this.$store.dispatch(UPDATE_ITEM_BY_ID, {
          url: 'api/orders/' + this.id + '/csr',
          contents: contents,
          showLoading: false,
        })
      }
      this.currentActiveTab = 1;
    },
    submitForm() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertLoading();

      let form = this.cloneForce(this.form);
      form.sale_price_currency_unit_id = form.currency_unit_id;
      for (let i = 0; i < form.order_export_dates.length; i++) {
        form.order_export_dates[i].export_date = moment(form.order_export_dates[i].export_date).format(LARAVEL_DATE_FORMAT);
      }
      for (let i = 0; i < form.order_fabrics.length; i++) {
        form.order_fabrics[i].deadline_start = moment(form.order_fabrics[i].deadline_start).format(LARAVEL_DATE_FORMAT)
        form.order_fabrics[i].deadline_end = moment(form.order_fabrics[i].deadline_end).format(LARAVEL_DATE_FORMAT)
        form.order_fabrics[i].planned = Number(form.order_fabrics[i].pastal) * Number(form.order_fabrics[i].unit_price);
      }
      for (let i = 0; i < form.order_size_and_colors.length; i++) {
        form.order_size_and_colors[i].total = this.sizeAndColorTotalByIndex(i);
        form.order_size_and_colors[i].export_total = 0;
        let tempBodySize = _.cloneDeepWith(form.order_size_and_colors[i].body_sizes);
        for (let key in tempBodySize) {
          if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
        }
        let temp = JSON.stringify(tempBodySize);
        form.order_size_and_colors[i] = {...form.order_size_and_colors[i], body_sizes: temp};
        for (let j = 0; j < form.order_size_and_colors[i].export_dates.length; j++) {
          form.order_size_and_colors[i].export_dates[j].export_date = moment(form.order_size_and_colors[i].export_dates[j].export_date).format(LARAVEL_DATE_FORMAT);
          form.order_size_and_colors[i].export_dates[j].date = moment(form.order_size_and_colors[i].export_dates[j].export_date).format(LARAVEL_DATE_FORMAT);
        }
      }
      form.csr = this.csr;
      form.esc_table_id = this.esc_id;
      let contents = new FormData();
      for (let key in form) {
        if (key === 'image') continue;
        this.createFormDataForList(contents, key, form[key]);
      }
      if (form.image) {
        contents.append('image', form.image);
      }

      this.$store.dispatch(CREATE_ITEM, {
        url: 'api/orders',
        contents: contents,
        showLoading: false,
      }).then((result) => {
        if (result.status) {
          self.form.is_submitted = true;
          self.form.image = null;
          self.convertReturnOrderValueToValidFormData(result.data);
          self.sweetAlertClose();
          self.sweetAlertSuccess(self.$t('general.successfully_created'))
          self.is_submitting = false;
          self.$router.push({name: 'order.index'});
        } else {
          self.isSubmitting = false;
          self.form.is_submitted = true;
          self.is_submitting = false;

          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.isSubmitting = true;
            self.form.is_submitted = false;
            self.is_submitting = true;
            self.sweetAlertError(response.data.message);
          } else {
            self.isSubmitting = true;
            self.form.is_submitted = false;
            self.is_submitting = true;
            self.sweetAlertError(response.data);
          }
        }
      }).catch((result) => {
        self.isSubmitting = false;
        self.$store.commit(SET_LOADING, false);
        self.sweetAlertClose();
      })
    },
    updateOrder() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertLoading();
      let form = this.cloneForce(this.form);
      form.order_expenses.map((item, index) => {
        delete item.order_expense_details
      })
      delete form.esc;
      delete form.csr;
      delete form.status;
      delete form.company;
      delete form.order_fabric_requirements.order_fabric;
      delete form.order_fabric_requirements.order_size_and_color;

      form.sale_price_currency_unit_id = form.currency_unit_id;
      for (let i = 0; i < form.order_export_dates.length; i++) {
        form.order_export_dates[i].export_date = moment(form.order_export_dates[i].export_date).format(LARAVEL_DATE_FORMAT);
      }
      
      for (let i = 0; i < form.order_fabrics.length; i++) {
        form.order_fabrics[i].deadline_start = moment(form.order_fabrics[i].deadline_start).format(LARAVEL_DATE_FORMAT)
        form.order_fabrics[i].deadline_end = moment(form.order_fabrics[i].deadline_end).format(LARAVEL_DATE_FORMAT)
        form.order_fabrics[i].planned = Number(form.order_fabrics[i].pastal) * Number(form.order_fabrics[i].unit_price);
      }
      for (let i = 0; i < form.order_size_and_colors.length; i++) {
        form.order_size_and_colors[i].total = this.sizeAndColorTotalByIndex(i);
        let tempBodySize = _.cloneDeepWith(form.order_size_and_colors[i].body_sizes);
        for (let key in tempBodySize) {
          if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
        }
        let temp = JSON.stringify(tempBodySize);
        form.order_size_and_colors[i] = {...form.order_size_and_colors[i], body_sizes: temp};

        for (let j = 0; j < form.order_size_and_colors[i].export_dates.length; j++) {
          form.order_size_and_colors[i].export_dates[j].export_date = moment(form.order_size_and_colors[i].export_dates[j].export_date).format(LARAVEL_DATE_FORMAT);
          form.order_size_and_colors[i].export_dates[j].date = moment(form.order_size_and_colors[i].export_dates[j].export_date).format(LARAVEL_DATE_FORMAT);
        }
      }
      form.csr = this.csr;

      if (this.esc_id) {
        form.esc_table_id = this.esc_id;
      }

      let contents = new FormData();
      for (let key in form) {
        if (key === 'image') continue;
        this.createFormDataForList(contents, key, form[key]);
      }
      if (this.form.image) {
        contents.append('image', this.form.image);
      }
      this.$store.dispatch(CREATE_ITEM, {
        url: 'api/orders/' + this.id,
        contents: contents,
        showLoading: false,
      }).then((result) => {
        self.isSubmitting = false;
        self.form.is_submitted = true;

        if (result.status) {
          self.form.image = null;
          self.convertReturnOrderValueToValidFormData(result.data);
          self.sweetAlertSuccess(self.$t('general.successfully_created'))
          self.$router.push({name: 'order.index'});
        } else {
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.is_submitting = false;
            self.form.is_submitted = true;
            self.$store.commit(SET_LOADING, false);
            self.sweetAlertError(response.data.message);
          } else {
            self.is_submitting = false;
            self.form.is_submitted = true;
            self.$store.commit(SET_LOADING, false);
            self.sweetAlertError(response.data);
          }
        }
        self.sweetAlertClose();
      }).catch((result) => {
        self.is_submitting = false;
        self.form.is_submitted = true;
        self.$store.commit(SET_LOADING, false);
        self.sweetAlertClose();
      })
    },
    submitAndContinueSupplier() {
      this.currentActiveTab = 2;
    },
    redirectToSelectSample() {
      let query = {
        type: this.type,
        id: this.id,
        redirect: 1,
        redirectName: 'order',
        ...this.$route.query
      }
      this.$router.replace({
        name: 'sample',
        query: query
      });
    },
    
    redirectToSelectESC() {
      let query = {
        type: this.type,
        id: this.id,
        redirect: 1,
        in_use: 0,
        redirectName: 'order',
        ...this.$route.query
      }
      this.$router.replace({
        name: 'esc-meeting.index',
        query: query
      });
    },
    onChangeOrderImage() {
      this.form.image = this.$refs.orderImage.files[0];
      let imageHolder = $('#image_holder');
      imageHolder.attr('src', URL.createObjectURL(this.form.image));
    },
    addExportDateInfo() {
      this.form.order_export_dates.push({
        export_date: moment(),
        amount: null,
      })
    },
    deleteExportDateInfo(index) {
      this.form.order_export_dates.splice(index, 1);
    },
    resetSizeAndColor(index){
      this.form.order_size_and_colors[index].body_sizes=_.cloneDeep(this.defaultBodySizes);
    },
    addNewSizeAndColor() {
      let item = JSON.parse(JSON.stringify(this.form.order_size_and_colors.slice(-1)[0]));
      item.id = null;
      item.selected_dates = {};
      item.export_dates=[];
      item.is_submit_order_export=false;
      this.form.order_size_and_colors.push(item)
    },
    deleteNewSizeAndColor(index) {
      if(this.form.order_size_and_colors.length>1){
        this.form.order_size_and_colors.splice(index, 1)
      }
    },
    addNewOrderFabric() {
      this.form.order_fabrics.push({
        id: null,
        deadline_start: this.form.order_fabrics.slice(-1)[0].deadline_start,
        deadline_end: this.form.order_fabrics.slice(-1)[0].deadline_end,
        type: null,
        width: null,
        weight: null,
        pastal: null,
        unit_price: null,
        unit_id: this.options.unitOptions3.find(item => item.code === 'KG').id,
        planned: null,
        actual: null,
        selected_order_fabric_compositions: [],
        order_fabric_compositions: [],
      });
      /*      this.form.order_size_and_colors.push({
              order_fabric_id: uuid,
              color_number: null,
              color_name: null,
              body_sizes: _.cloneDeepWith(this.defaultSizeJSON),
              total: null,
              export_total: null,
            })*/
    },
    deleteNewOrderFabric(index) {
      this.form.order_fabrics.splice(index, 1);
    },
    onExpensesSave(payload) {
      let oldValue = payload.oldValue, newValue = payload.newValue, current = this.form.order_expenses;
      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < current.length; j++) {
            if (Number(current[j].expense_id) === Number(oldValue[i])) {
              current.splice(j, 1);
              break;
            }
          }
        }
      }
      for (let i = 0; i < newValue.length; i++) {
        if (!oldValue.includes(newValue[i]) && !this.form.selected_expenses.includes(newValue[i])) {
          this.form.order_expenses.push({
            actual: null,
            amount: null,
            planned: null,
            currency_unit_id: Object.keys(this.options.currencyOptions)[0].toString(),
            unit_id: this.options.unitOptions3.find(item => item.code === 'ADET').id.toString(),
            expense_id: newValue[i],
            is_default: 0,
            name: this.options.expensesOptions[newValue[i]].name
          });
        }
      }
    },
    DeleteExpensesSave(index) {
      this.form.order_expenses.splice(index, 1);
    },
    parseGradingOrder(grading) {
        let order;
        if (grading.includes('s'))
            order = -1;
        else if (grading.includes('m'))
            order = 0;
        else if (grading.includes('l'))
            order = 1;
        const n = Number(grading.match(/\d+(?!X)/))
        const numXes = grading.match(/x*/)[0].length
        const mul = n ? n : numXes + 1
        return order * mul;
    },
    sortArrayOfGradings(array) {
        return array.sort((a, b) => {
          a =a.name;
          b =b.name;
            if (!isNaN(a) && !isNaN(b))
                  return a-b;
            if (!isNaN(a) && isNaN(b))
                return -1
            if (isNaN(a) && !isNaN(b))
                return 1
            if (isNaN(a) && isNaN(b)) {
                let aOrder = this.parseGradingOrder(a.toLowerCase());
                let bOrder = this.parseGradingOrder(b.toLowerCase());
                return aOrder-bOrder;
            }
        });
    },
    getDefaultBodySize(payload){
      let self=this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/body-sizes',
        filters: {
          company_id: payload
        }
      }).then(result => {
        if (result.status) {
          self.options.defaultBodySizes = self.convertArrayToObjectByKey(result.data, 'id');
          let json = {};
          for (let key in self.options.defaultBodySizes) {
            json[key] = {
              body_size_id: key,
              amount: null,
              name: self.getValueByDottedKey(self.options.defaultBodySizes[key], 'translations.0.name'),
            }
          }
          self.defaultBodySizes = json;
        }
      })
    },
    onCompanyChange(payload) {
      if (this.form.company_id === null || this.form.company_id === undefined) return;
      if ((payload.oldValue === null || payload.oldValue === undefined) && this.id) return;
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/body-sizes',
        filters: {
          company_id: this.form.company_id
        }
      }).then(result => {
        if (result.status) {
          self.options.bodySizeOptions = self.convertArrayToObjectByKey(result.data, 'id');
          let json = {};
          for (let key in self.options.bodySizeOptions) {
            json[key] = {
              body_size_id: key,
              amount: null,
              name: self.getValueByDottedKey(self.options.bodySizeOptions[key], 'translations.0.name')
            }
          }
          self.defaultSizeJSON = json;
          this.defaultBodySizes=json;
          for (let i = 0; i < self.form.order_size_and_colors.length; i++) {
            self.form.order_size_and_colors[i].instruction_check=true;
            self.form.order_size_and_colors[i].body_sizes = {...self.defaultSizeJSON};
          }
        }
      })
    },
    onOrderFabricCompositionChange(payload) {
      let index = payload.name.split('-')[1], oldValue = payload.oldValue, newValue = payload.newValue;
      let currentFabricComposition = this.form.order_fabrics[index];

      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < currentFabricComposition.order_fabric_compositions.length; j++) {
            if (Number(currentFabricComposition.order_fabric_compositions[j].fabric_composition_id) === Number(oldValue[i])) {
              currentFabricComposition.order_fabric_compositions.splice(j, 1);
              break;
            }
          }
        }
      }
      let n = newValue.length - currentFabricComposition.order_fabric_compositions.length;
      let remaining = 0;
      for (let i = 0; i < currentFabricComposition.order_fabric_compositions.length; i++) {
        remaining += Number(currentFabricComposition.order_fabric_compositions[i].amount);
      }
      let newPercent = 0;
      if ((100 - remaining) !== 0 || n > 0) {
        newPercent = ((100 - remaining) / n).toFixed(0);
      }
      if (Number(newPercent) < 0) newPercent = 0;

      for (let i = 0; i < newValue.length; i++) {
        if (!oldValue.includes(newValue[i]) && !currentFabricComposition.selected_order_fabric_compositions.includes(newValue[i])) {
          currentFabricComposition.order_fabric_compositions.push({
            fabric_composition_id: newValue[i],
            amount: newPercent,
            valid: false,
          });
        }
      }
    },
    setup() {
      if (this.$route.query === this.$route.query) {
        this.resetForm();
      }
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t('general.dashboard'), route: "dashboard"},
        {title: this.$t('order.orders'), route: "order.index"},
        {title: this.id ? this.$t('order.edit_order') : this.type === 1 ? this.$t('order.create_order_from_esc') : this.$t('order.create_order_from_sample')}
      ]);
      this.sweetAlertLoading();
      let self = this, promises = [];

      if (this.esc_id !== null) {
        this.form.esc_table_id = this.esc_id;
      }

      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/companies',
      }))
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/statuses',
        filters: {
          type_id: 2,
        }
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/currency'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/users/qc-staff'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-compositions'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/expenses',
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/units',
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/snap-types'
      }));
      promises.push(this.$store.dispatch(CREATE_ITEM, {
        url: 'api/csr/supplier-companies',
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/currency-rate'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/vat-rates',
        filters: {}
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-yarn-types'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-knit-types'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-types'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-process'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/fabric-additions'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/press-types'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/expiries'
      }));
      promises.push(this.$store.dispatch(GET_ITEMS, {
        url: 'api/product-process'
      }));


      promises.push(this.$store.dispatch(CREATE_ITEM, {
        url: 'api/order/form-stop-options',
      }));

      Promise.all(promises).then(results => {
        if (results[0].status) {
          self.options.customerOptions = self.convertArrayToObjectByKey(results[0].data, 'id', 'name')
        }
        if (results[1].status) {
          self.options.orderStatusOptions = self.convertArrayToObjectByKey(results[1].data, 'id', 'translations.0.name');
          self.form.status_id = Object.keys(self.options.orderStatusOptions)[0].toString();
        }
        if (results[2].status) {
          let items = results[2].data;
          self.options.currencyOptions = self.convertArrayToObjectByKey(items, 'id', 'code');
          self.form.currency_unit_id = items.find(item => item.code == 'TRY').id;
          self.options.currencyRawOptions = items;
        }
        if (results[3].status) {
          self.options.qcUserOptions = self.convertArrayToObjectByKey(results[3].data, 'id', 'name');
        }
        if (results[4].status) {
          self.options.fabricCompositionOptions = self.convertArrayToObjectByKey(results[4].data, 'id', 'translations.0.name');
        }
        if (results[6].status) {
          self.options.unitOptions = self.convertArrayToObjectByKey(results[6].data, 'id', 'translations.0.name');
          let tmpUnits = self.convertArrayToObjectByKey(results[6].data, 'id', 'translations.0.name');
          self.options.unitOptions2 = tmpUnits;
          self.options.unitOptions3 = results[6].data;
        }
        if (results[5].status) {
          let temp = results[5].data;
          self.form.order_expenses = [];
          let tempExpenses = [], tempOptionalExpenses = [], tempFormOptionExpenses = [];
          for (let i = 0; i < temp.length; i++) {
            {
              if (Number(temp[i].is_default) === 1) {
                self.form.order_expenses.push({
                  planned: null,
                  actual: null,
                  amount: null,
                  unit_id: this.options.unitOptions3.find(item => item.code === 'ADET').id.toString(),
                  expense_id: temp[i].id,
                  is_default: Number(temp[i].is_default),
                  ignore_check_price: Number(temp[i].ignore_check_price),
                  currency_unit_id: Object.keys(self.options.currencyOptions)[0].toString(),
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
                tempOptionalExpenses.push(temp[i])
                tempFormOptionExpenses.push({
                  expense_id: temp[i].id,
                  amount: null,
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                })
              } else {
                tempExpenses.push({
                  expense_id: temp[i].id,
                  amount: null,
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                })
              }
            }
          }
          self.options.expensesOptions = self.convertArrayToObjectByKey(tempExpenses, 'expense_id');
          self.options.optionalExpenseOptions = self.convertArrayToObjectByKey(tempFormOptionExpenses, 'expense_id');
          // self.options.form.optionalExpenses = [...tempFormOptionExpenses];

          if (self.esc_id !== 0 && !isNaN(self.esc_id)) {
            self.$store.dispatch(GET_ITEMS, {
              url: `api/esc-tables/${self.esc_id}?single=1`,
            }).then(result => {
              if (result.status) {
                self.form.image_link = result.data.image_link;
                self.form.image_link_large = result.data.image_link_large;
                self.form.esc_id = result.data.id;
                self.form.esc = result.data;
                self.form.company_id = result.data.company_id;
                self.form.model = result.data.model;
                self.form.currency_unit_id = result.data.currency_unit_id;
                self.form.description = result.data.description;
                self.form.instruction_description = result.data.instruction_description;
                self.form.amount_per_parcel = result.data.amount_per_parcel;

                if (!self.id) {
                  let tempExpenses = self.convertArrayToObjectByKey(result.data.esc_table_expenses, 'expense_id', 'amount')
                  let tempExpensesQuantity= self.convertArrayToObjectByKey(result.data.esc_table_expenses, 'expense_id', 'quantity')
                  let tempExpensesUnits= self.convertArrayToObjectByKey(result.data.esc_table_expenses, 'expense_id', 'unit_id')
                  let tempOrderExpenses = self.convertArrayToObjectByKey([...self.form.order_expenses], 'expense_id');
                  for (let key in tempExpenses) {
                    if (key in tempOrderExpenses) {
                      let index = Object.keys(tempOrderExpenses).indexOf(key);
                      self.form.order_expenses[index].planned = tempExpenses[key];
                      self.form.order_expenses[index].amount = tempExpensesQuantity[key];
                      self.form.order_expenses[index].unit_id = tempExpensesUnits[key];
                    } else {
                      let name = '';
                      if (key in self.options.expensesOptions) {
                        name = self.options.expensesOptions[key].name;
                      } else {
                        name = self.options.optionalExpenseOptions[key].name;
                      }
                      self.form.order_expenses.push({
                        actual: null,
                        amount: tempExpensesQuantity[key],
                        planned: tempExpenses[key],
                        currency_unit_id: tempExpensesUnits[key],
                        unit_id: tempExpensesUnits[key],
                        expense_id: key,
                        is_default: 0,
                        name: name,
                      })
                    }
                  }
                  self.form.order_fabrics = [];
                  if (result.data.fabric_calculations.length > 0) {
                    result.data.fabric_calculations.forEach((fabric, index) => {
                      fabric.start_date = moment(fabric.start_date).format(LARAVEL_DATE_FORMAT);
                      fabric.end_date = moment(fabric.end_date).format(LARAVEL_DATE_FORMAT);
                      fabric.type = fabric.fabric;
                      fabric.pastal = fabric.consumption;
                      fabric.unit_price = fabric.price;
                      fabric.unit_id = this.options.unitOptions3.find(item => item.id === +fabric.unit_id).id.toString()
                      fabric.planned = null;
                      fabric.actual = null;
                      fabric.selected_order_fabric_compositions = [];
                      fabric.order_fabric_compositions = [];
                      fabric.currency_unit_id=fabric.currency_unit? fabric.currency_unit.code:'TL';
                      fabric.exchange_rate=fabric.exchange_rate? fabric.exchange_rate:1;
                      self.form.order_fabrics.push({...fabric})
                    })
                  }
                }
              }
            })
          }
          if (self.sample_id !== null && !isNaN(self.sample_id)) {
            self.form.sample_id = this.sample_id;
            self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
              url: `api/samples/${this.sample_id}`,
              id: self.sample_id,
            }).then((result) => {
              if (result.status) {
                self.form.sample = result.data;

                // TODO: get fabrics
                if (!self.id && result.data.sample_product_informations.length > 0) {
                  self.form.order_fabrics = [];
                  result.data.sample_product_informations.forEach(fabric => {
                    fabric.order_id = null;
                    fabric.deadline_start = moment();
                    fabric.deadline_end = moment();
                    fabric.type = fabric.fabric_type.translations[0].name;
                    fabric.pastal = null;
                    fabric.unit_id = this.options.unitOptions3.find(item => item.id === +fabric.unit_id).id.toString()
                    fabric.planned = null;
                    fabric.actual = null;
                    fabric.selected_order_fabric_compositions = [];
                    fabric.order_fabric_compositions = [];
                    fabric.currency_unit_id=fabric.currency_unit? fabric.currency_unit.code:'TL';
                    fabric.exchange_rate=fabric.exchange_rate? fabric.exchange_rate:1;
                    if ('fabric_composition_sample_product_informations' in fabric) {
                      fabric.fabric_composition_sample_product_informations.forEach((composition) => {
                        fabric.selected_order_fabric_compositions.push(composition.fabric_composition_id);
                        fabric.order_fabric_compositions.push({
                          fabric_composition_id: composition.fabric_composition_id,
                          amount: composition.percent,
                          valid: false,
                        })
                      })
                    }

                    self.form.order_fabrics.push({...fabric});
                   
                  })
                }
              }
            })
          }
          if (self.id) {
            self.form.is_submitted = true;
            self.currentActiveTab = 4;

            self.getInstructionTypes();
            self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
              url: `api/orders/${self.id}`,
            }).then((result) => {
              if (result.status) {
                self.convertReturnOrderValueToValidFormData(result.data);
                self.tempFormOrderExpensesForQrScanner=result.data.order_expenses?result.data.order_expenses:null;
                if(this.$route.query.qr_scanner && this.$route.query.qr_type=="expense"){
                  let self =this;
                  self.currentActiveTab=2;
                  let expense = this.$route.query.expense
                  this.changeSelectedExpense(expense)
                }
                if(this.$route.query.qr_scanner && this.$route.query.qr_type){
                  let self =this;
                  self.currentActiveTab=2;
                  self.activeTab=2;
                }
              }
            })
          } else {
            self.form.fabric_supply_type_id = null;
          }
        }

        if (results[8].status) {
          self.options.dispatchLocationOptions = self.convertArrayToObjectByKey(results[8].data, 'supplier_company_id', 'supplier_company.name');
        }
        if (results[9].status) {
          self.options.currencyRateOptions = results[9].data.data;
        }
        if (results[10].status) {
          self.options.VATRateOptionsRaw = results[10].data;
        }
        if (results[11].status) {
          self.options.fabricYarnTypeOptions = self.convertArrayToObjectByKey(results[11].data, 'id', 'translations.0.name');
        }
        if (results[12].status) {
          self.options.fabricKnitTypeOptions = self.convertArrayToObjectByKey(results[12].data, 'id', 'translations.0.name');
        }
        if (results[13].status) {
          self.options.fabricTypeOptions = self.convertArrayToObjectByKey(results[13].data, 'id', 'translations.0.name');
        }
        if (results[14].status) {
          self.options.fabricProcessTypeOptions = self.convertArrayToObjectByKey(results[14].data, 'id', 'translations.0.name');
        }
        if (results[15].status) {
          self.options.fabricAdditionOptions = self.convertArrayToObjectByKey(results[15].data, 'id', 'translations.0.name');
        }
        if (results[16].status) {
          self.options.fabricPrintTypeOptions = self.convertArrayToObjectByKey(results[16].data, 'id', 'translations.0.name');
        }
        if (results[17].status) {
          self.options.expirationTypeOptions = self.convertArrayToObjectByKey(results[17].data, 'id');
        }
        if (results[18].status) {
          self.options.productProcesses = self.convertArrayToObjectByKey(results[18].data, 'id', 'translations.0.name');
        }
        if (results[7].status) {
          self.options.snapTypeOptions = self.convertArrayToObjectByKey(results[7].data, 'id', 'translations.0.name');
        }

        if (results[19].status) {
          self.options.formStopOptions = self.convertArrayToObjectByKey(results[19].data, 'id', 'translation.name');
        }
        
        self.sweetAlertClose();
      })
    },
  },
  mounted() {
    if (!this.isUserGranted('Order', ['create', 'update', 'view'])) {
      return false;
    }

    this.setup()


    if(this.$route.query.qr_scanner && this.$route.query.qr_type=="expense"){
      setTimeout(() => {
          let instructionsId = this.$route.query.instruction_id

          let element = this.$refs["expense_"+instructionsId][0]
          if(element){
            element.scrollIntoView({behavior: "smooth"})
          }
        }, 2500);
    }
    
  },
  watch: {
    vatRateOptions(value) {
      if (value) {
        this.options.VATRateOptions = value;
      }
    }, 
    'form.order_size_and_colors': {
      handler(newValue, oldValue) {
        newValue.forEach((orderSizeAndColor, index) => {
          let permission = false;
          
          if(orderSizeAndColor && orderSizeAndColor.export_dates){ 
            if(orderSizeAndColor.export_dates.length>0){
              const hasEmptyAmount = orderSizeAndColor.export_dates.some((exportDatesBodySize) => {
                return Object.keys(exportDatesBodySize.body_sizes).some((bodySize) => {
                  const amount = exportDatesBodySize.body_sizes[bodySize].amount;
                  return (
                    !amount || isNaN(Number(amount))
                  );
                  
                });
                
              });
              if (hasEmptyAmount==false) {
                  permission = true;
                }
                Vue.set(this.form.order_size_and_colors[index],'is_submit_order_export',permission);
              this.setOrderSizeExport({
                tmpOrderSizeAndColorId:index,
                value:permission
              });
            }
          }
          
        });

        if(this.tmpOrderSizeAndColorId===null || this.tmpOrderSizeAndColorId==="") return;
        const orderExportSelectedDates = this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].selected_dates;

      if (orderExportSelectedDates.length > 0) {
        orderExportSelectedDates.forEach((item) => {
          const date = this.formatedOrderExport[item];
          const findItem = _.find(this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].export_dates, { 'date': date.id });
          const bodySizes = _.cloneDeep(this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].body_sizes);
          Object.keys(bodySizes).forEach((sizeKey) => {
            bodySizes[sizeKey].new_amount = bodySizes[sizeKey].amount;
          });
          if (!findItem) {
            this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].export_dates.push({
              "date": date.id,
              "export_date": date.id,
              "order_export_date_id":date.order_export_date_id??null,
              "color_name": _.cloneDeep(this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].color_name),
              "color_number": _.cloneDeep(this.form.order_size_and_colors[this.tmpOrderSizeAndColorId].color_number),
              "body_sizes": bodySizes,
            });
          }
        });
      }
      this.$nextTick()
      },
      deep: true
    },
   
    '$route'() {
      this.id = this.$route.params.id;
      this.type = Number(this.$route.params.type);
      this.esc_id = Number(this.$route.query.esc_id);
      this.sample_id = Number(this.$route.query.sample_id);
      this.setup();
    },
    'form.order_expenses': {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        newValue.map(item => {
          item.planned = item.planned ? item.planned.replace(",",".") : item.planned
          return item;
        })
      },
      deep: true
    },
    'form.order_fabrics': {
      handler: function (newValue) {
        newValue.map(item => {
          if(item.currency_unit_id!=null&&item.exchange_rate==null){
            let currencyOptions=_.cloneDeep(this.options.currencyRateOptions);
            let findSelectedCurrency = _.find(currencyOptions, { 'code': item.currency_unit_id});
            item.exchange_rate=findSelectedCurrency.rate;
          }
          if(item.currency_unit_id!=null&&item.exchange_rate!=null){
            item.currency_price=(item.unit_price/item.exchange_rate).toFixed(4);
            item.currency_price_total=(item.currency_price*item.pastal).toFixed(4);
          }
          item.pastal = item.pastal ? item.pastal.replace(",",".") : item.pastal
          item.unit_price = item.unit_price ? item.unit_price.replace(",",".") : item.unit_price
          return item;
        })
      },
      deep: true
    },
    'form.company_id'(newValue, oldValue) {
      this.onCompanyChange({newValue, oldValue});
    },
    currentActiveTab(value) {
      this.instructionTypeId = null;
      this.isValidFabricComposition()
      if (Number(value) === 2 && this.expense_data.selectedExpenseID === null) { 
        this.expense_data.selectedExpenseID = Number(this.form.order_expenses[0].expense_id);
        this.expense_data.typeId = Number(this.form.order_expenses[0].expense.type_id);
        this.expense_data.orderExpenseId = Number(this.form.order_expenses[0].id);
        this.instructionTypeId = Number(this.form.order_expenses[0].expense.instructionable[0].id);
        this.expense_data.instructionTypeId = Number(this.form.order_expenses[0].expense.instructionable[0].id);
        this.options.supplierCompanyOptions=[];
        this.csrSupplierCompanies=[];
        this.getSupplierCompanies();
      }
    },
    activeTab(value) {
      this.instructionTypeId = null;

      if (Number(value) === 1) {
        this.expense_data.selectedExpenseID = Number(this.form.order_expenses[0].expense_id);
        this.expense_data.typeId = Number(this.form.order_expenses[0].expense.type_id);
        this.expense_data.orderExpenseId = Number(this.form.order_expenses[0].id);
        this.instructionTypeId = Number(this.form.order_expenses[0].expense.instructionable[0].id);
        this.expense_data.instructionTypeId = Number(this.form.order_expenses[0].expense.instructionable[0].id);
      }

      if (Number(value) === 2) {
        this.instructionTypeId = null;
      }
      this.options.supplierCompanyOptions=[];
      this.csrSupplierCompanies=[];

      this.getSupplierCompanies();
    },
    'csr.join_life': {
      handler: function (newValue, oldValue) {
        if (newValue) {
          if (newValue.includes("1")) {
            if (newValue.length > 1) {
              this.csr.join_life = ["1"];
            }
          }
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

.table thead th, .table thead td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.table th, .table td {
  padding: 0.50rem !important;
  vertical-align: middle !important;
}

.cut-move,
.cut-enter-active,
.cut-leave-active {
  transition: opacity 0.5s ease;
}

.cut-enter-active,
.cut-leave-active {
  transition: opacity 0.5s ease;
}

.cut-enter-from,
.cut-leave-to {
  opacity: 0;
}
</style>


